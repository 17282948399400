import React, { useState, useEffect, useRef } from "react";
import "./OnBoarding.css";
import {
  Row,
  Col,
  Image,
  Container,
  Tab,
  Nav,
  Form,
  InputGroup,
  Button,
  FormGroup,
  FormControl,
} from "react-bootstrap";
import { Link, useLocation, useHistory } from "react-router-dom";
import LoginSlider from "./LoginSlider";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  forgotPasswordStart,
  userLoginStart,
  userRegisterStart,
} from "../../store/actions/UserAction";
import { connect } from "react-redux";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import ForgetPasswordVerificationModal from "./ForgetPasswordVerificationModal";

const ForgotPasswordIndex = (props) => {
  const history = useHistory();
  const location = useLocation();

  const [step, setStep] = useState(1);

  const [skipRender, setSkipRender] = useState(true);

  const [email, setEmail] = useState("");

  const [passwordVerification, setPasswordVerification] = useState(null);

  const [forgotPasswordData, setForgotPasswordData] = useState({
    attribute: "email",
    value: ""
  });

  const formRef = useRef(null);

  const closePasswordVerificationModal = () => {
    setPasswordVerification(null);
  };

  useEffect(() => {
    if (
      !skipRender &&
      !props.forgotPassword.loading &&
      Object.keys(props.forgotPassword.data).length > 0 &&
      props.forgotPassword.data.success
    ) {
      setPasswordVerification(true);
    }
    setSkipRender(false);
  }, [props.forgotPassword]);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
  });

  const mobileValidationSchema = Yup.object().shape({
    mobile: Yup.string().required("Required"),
  });

  const handleForgotPassword = (values) => {
    setEmail(values.email);
    setForgotPasswordData({
      attribute: "email",
      value: values.email
    });
    props.dispatch(forgotPasswordStart(values));
  };

  const handleForgotPasswordByMobile = (values) => {
    if (validateMobileNumber(values.mobile)) {
      const intlNo = formatPhoneNumberIntl(values.mobile);
      const countryCode = intlNo.substring(
        intlNo.indexOf("+") + 1,
        intlNo.indexOf(" ")
      );
      const mobile = intlNo
        .substring(intlNo.indexOf(" "), intlNo.length)
        .replaceAll(" ", "");
      setForgotPasswordData({
        attribute: "mobile",
        value: mobile
      });
      props.dispatch(forgotPasswordStart({
        mobile: mobile,
      }));
    }
  };

  const validateMobileNumber = (value) => {
    if (value) {
      if (!isPossiblePhoneNumber(value)) {
        formRef.current.setFieldError("mobile", "Not a Phone number");
        return false;
      } else if (!isValidPhoneNumber(value)) {
        formRef.current.setFieldError("mobile", "Invalid Phone number");
        return false;
      } else {
        formRef.current.setFieldError("mobile", "");
        return true;
      }
    } else {
      formRef.current.setFieldError("mobile", "Required");
      return false;
    }
  };

  return (
    <>
      <div className="authentication-sec">
        <Container>
          <div className="autentication-box">
            <Row className="align-items-center">
              <Col md={6} className="border-right">
                <LoginSlider />
              </Col>
              <Col md={6} className="border-left">
                <div className="login-auth-sec">
                  <div className="Login-nav-link">
                    <ul>
                      <li
                        className={
                          step === 1 ? "active" : ""
                        }
                        onClick={() => setStep(1)}
                      >
                        <Link to="#">Mail</Link>
                      </li>
                      <li
                        className={
                          step === 2 ? "active" : ""
                        }
                        onClick={() => setStep(2)}
                      >
                        <Link to="#">Mobile</Link>
                      </li>
                    </ul>
                  </div>
                  {step === 1 &&
                    <Formik
                      initialValues={{
                        email: "",
                      }}
                      validationSchema={validationSchema}
                      onSubmit={(values) => handleForgotPassword(values)}
                    >
                      {({ errors, touched }) => (
                        <FORM className="auth-form">
                          <div className="sign-in-sec">
                            <p>
                              Enter your email address and we’ll send you
                              instructions to reset your password.
                            </p>
                            <FormGroup className="mb-4">
                              <Form.Label>E-mail*</Form.Label>
                              <InputGroup className={`${touched.email && errors.email ? "is-invalid" : ""
                                }`}>
                                <InputGroup.Prepend>
                                  <InputGroup.Text id="basic-addon1">
                                    <Image
                                      src={
                                        window.location.origin +
                                        "/assets/images/auth/mail.svg"
                                      }
                                    />
                                  </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Field
                                  className={`form-control ${touched.email && errors.email
                                    ? "is-invalid"
                                    : ""
                                    }`}
                                  type="email"
                                  placeholder="Enter Your Email Address"
                                  name="email"
                                />
                              </InputGroup>
                              <ErrorMessage
                                component={"div"}
                                name="email"
                                className="text-danger small"
                              />
                            </FormGroup>
                            <div className="onboard-btn-sec">
                              <Button
                                className="onboard-btn"
                                type="submit"
                                disabled={props.forgotPassword.buttonDisable}
                              >
                                {props.forgotPassword.loadingButtonContent
                                  ? props.forgotPassword.loadingButtonContent
                                  : "Submit"}
                              </Button>
                            </div>
                            <div className="sign-up-link">
                              <Link to="/">Sign In</Link>
                              <Link to="/register">Sign Up</Link>
                            </div>
                          </div>
                        </FORM>
                      )}
                    </Formik>
                  }
                  {step === 2 &&
                    <Formik
                      initialValues={{
                        mobile: "",
                      }}
                      validationSchema={mobileValidationSchema}
                      onSubmit={(values) => handleForgotPasswordByMobile(values)}
                      innerRef={formRef}
                    >
                      {({ errors, values, touched, setFieldValue }) => (
                        <FORM className="auth-form">
                          <div className="sign-in-sec">
                            <p>
                              Enter your email address and we’ll send you
                              instructions to reset your password.
                            </p>
                            <FormGroup className="mb-4">
                              <Form.Label>Mobile*</Form.Label>
                              <PhoneInput
                                defaultCountry="US"
                                placeholder="Enter phone number"
                                value={values.mobile}
                                onChange={(value) =>
                                  setFieldValue("mobile", value)
                                }
                                onBlur={() => validateMobileNumber(values.mobile)}
                                international
                                countryCallingCodeEditable={false}
                                className={`${touched.mobile && errors.mobile
                                  ? "is-invalid"
                                  : ""
                                  }`}
                              />
                              <ErrorMessage
                                component={"div"}
                                name="mobile"
                                className="text-danger small"
                              />
                            </FormGroup>
                            <div className="onboard-btn-sec">
                              <Button
                                className="onboard-btn"
                                type="submit"
                                disabled={props.forgotPassword.buttonDisable}
                              >
                                {props.forgotPassword.loadingButtonContent
                                  ? props.forgotPassword.loadingButtonContent
                                  : "Submit"}
                              </Button>
                            </div>
                            <div className="sign-up-link">
                              <Link to="/">Sign In</Link>
                              <Link to="/register">Sign Up</Link>
                            </div>
                          </div>
                        </FORM>
                      )}
                    </Formik>
                  }
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      {passwordVerification ? (
        <ForgetPasswordVerificationModal
          passwordVerification={true}
          forgotPasswordData={forgotPasswordData}
          closePasswordVerificationModal={closePasswordVerificationModal}
        />
      ) : null}
    </>
  );
};

const mapStateToPros = (state) => ({
  forgotPassword: state.users.forgotPasswordInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(ForgotPasswordIndex);