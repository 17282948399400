import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Image,
  Container,
  Tab,
  Nav,
  Form,
  InputGroup,
  Button,
  FormGroup,
  FormControl,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { connect } from "react-redux";
import { updateOnboardingStart } from "../../store/actions/WalletAppAction";
import { getSuccessNotificationMessage } from "../helper/NotificationMessage";
import { createNotification } from "react-redux-notify";

const LinkYourAccount = (props) => {

  const [skipRender, setSkipRender] = useState(true);

  const validateLink = (value) => {
    if (value.replaceAll(" ", "") !== value) {
      return "Invalid link";
    }
    return "";
  };

  const handleSubmit = (values) => {
    props.dispatch(updateOnboardingStart(values));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !props.onboardingUpdate.loading &&
      Object.keys(props.onboardingUpdate.data).length > 0
    ) {
      props.dispatch(createNotification(getSuccessNotificationMessage("Social Links Updated")));
    }
    setSkipRender(false);
  }, [props.onboardingUpdate]);

  return (
    <>
      <div className="header-back-btn">
        <Button onClick={() => props.setStep(6)}>
          <Image
            src={
              window.location.origin +
              "/assets/images/onboarding-icons/back.svg"
            }
            className="back-btn"
          />
        </Button>
      </div>

      <div className="create-link-sec login-auth-sec ">
        <div className="sign-in-sec">
          <div className="invite-link-section">
            <h4 className="onboard-link-title">Link your Socials</h4>
            <p>
              You can add your social media accounts to increase the strength
              of your profile
            </p>
            <div className="link-social-account-sec mt-4">
              <Formik
                initialValues={{
                  insta_username: props.profile.data.insta_username
                    ? props.profile.data.insta_username
                    : "",
                  facebook_username: props.profile.data.facebook_username
                    ? props.profile.data.facebook_username
                    : "",
                  twitter_username: props.profile.data.twitter_username
                    ? props.profile.data.twitter_username
                    : "",
                  snap_username: props.profile.data.snap_username
                    ? props.profile.data.snap_username
                    : "",
                }}
                // validationSchema={{}}
                onSubmit={(values) => handleSubmit(values)}
              >
                {({ errors, touched }) => (
                  <FORM>
                    <ul className="list-unstyled">
                      <li>
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                              <span>
                                <Image
                                  src={
                                    window.location.origin +
                                    "/assets/images/onboarding-icons/instagram.svg"
                                  }
                                />
                              </span>
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <Field
                            type="text"
                            placeholder="Link your account"
                            className="form-control"
                            name="insta_username"
                            validate={validateLink}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="insta_username"
                            className="text-danger small"
                          />
                        </InputGroup>
                      </li>
                      <li>
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                              <span>
                                <Image
                                  src={
                                    window.location.origin +
                                    "/assets/images/onboarding-icons/facebook.svg"
                                  }
                                />
                              </span>
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <Field
                            type="text"
                            placeholder="Link your account"
                            className="form-control"
                            name="facebook_username"
                            validate={validateLink}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="facebook_username"
                            className="text-danger small"
                          />
                        </InputGroup>
                      </li>
                      <li>
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                              <span>
                                <Image
                                  src={
                                    window.location.origin +
                                    "/assets/images/onboarding-icons/twitter.svg"
                                  }
                                />
                              </span>
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <Field
                            type="text"
                            placeholder="Link your account"
                            className="form-control"
                            name="twitter_username"
                            validate={validateLink}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="twitter_username"
                            className="text-danger small"
                          />
                        </InputGroup>
                      </li>
                      <li>
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                              <span>
                                <Image
                                  src={
                                    window.location.origin +
                                    "/assets/images/onboarding-icons/snapchat.svg"
                                  }
                                />
                              </span>
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <Field
                            type="text"
                            placeholder="Link your account"
                            className="form-control"
                            name="snap_username"
                            validate={validateLink}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="snap_username"
                            className="text-danger small"
                          />
                        </InputGroup>
                      </li>
                    </ul>
                    <div className="onboard-btn-sec">
                      <Button
                        disabled={props.onboardingUpdate.buttonDisable}
                        className="onboard-btn"
                        type="submit">
                        {props.onboardingUpdate.loadingButtonContent
                          ? props.onboardingUpdate.loadingButtonContent
                          : "Update"}
                      </Button>
                    </div>
                  </FORM>
                )}
              </Formik>
            </div>
          </div>

          <div className="home-page-link mt-4 text-center">
            <Link to="/home">Go To Homepage</Link>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  onboardingUpdate: state.walletApp.onboardingUpdate,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(LinkYourAccount);
