import React from "react";
import {
  Form,
  Button,
  Image,
  Modal,
  Container,
  Row,
  Col,
} from "react-bootstrap";

const CreatePostModal = (props) => {
  return (
    <>
      <Modal
        className="modal-dialog-center withdraw-kyc-modal"
        size="md"
        centered
        show={props.createPost}
        onHide={props.closeCreatePost}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Post</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="create-post-modal-sec">
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Decription</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Compose new post..."
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Category</Form.Label>
                <Form.Control type="text" placeholder="Choose Category" />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Price</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Set price for the post"
                />
              </Form.Group>

              <div className="create-post-btn">
                <Button className="settings-submit-btn" type="submit">
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreatePostModal;
