import { call, put, takeLatest, all } from "redux-saga/effects";
import {
  LOAD_MORE_USER_PROFILE_ITEMS_LIST_START,
  NFT_USER_PROFILE_ITEMS_LIST_START,
  FETCH_NFT_COLLECTIONS_LIST_START,
  SAVE_NFT_COLLECTIONS_ITEMS_START,
} from "../actions/ActionConstant";
import { getErrorNotificationMessage } from "../../components/helper/NotificationMessage";
import {
  nftUserProfileItemsListSuccess,
  nftUserProfileItemsListFailure,
  loadMoreUserProfileItemsSuccess,
  loadMoreUserProfileItemsFailure,
  fetchNftCollectionsListSuccess,
  fetchNftCollectionsListFailure,
  saveNftCollectionsItemsSuccess,
  saveNftCollectionsItemsFailure,
} from "../actions/NftAction";
import { createNotification } from "react-redux-notify";
import axios from "axios";

const nftAPI = axios.create({
  baseURL: "https://cms-socialmarketplace.blockstall.com/api/user/",
});

function* nftUserProfileItemsListAPI(action) {
  try {
    const response = yield call(
      nftAPI.post,
      "nft_user_profile_items_list",
      action.data
    );

    if (response.data.success) {
      yield put(nftUserProfileItemsListSuccess(response.data.data));
    } else {
      yield put(nftUserProfileItemsListFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(nftUserProfileItemsListFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* loadMoreUserProfileItemAPI(action) {
  try {
    const response = yield call(
      nftAPI.post,
      "nft_user_profile_items_list",
      action.data
    );

    if (response.data.success) {
      yield put(loadMoreUserProfileItemsSuccess(response.data.data));
    } else {
      yield put(loadMoreUserProfileItemsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(loadMoreUserProfileItemsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchNftCollectionsListAPI(action) {
  try {
    let formData = new FormData();
    formData.append("id", localStorage.getItem("userId") ? localStorage.getItem("userId") : "");
    formData.append("token", localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : "");
    const response = yield call(
      nftAPI.post,
      "nft_collections_list",
      formData
    );
    if (response.data.success) {
      yield put(fetchNftCollectionsListSuccess(response.data.data));
    } else {
      yield put(fetchNftCollectionsListFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchNftCollectionsListFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* saveNftCollectionsItemsAPI(action) {
  try {
    const response = yield call(
      nftAPI.post,
      "nft_collection_items_save",
      {
        id: localStorage.getItem("userId") ? localStorage.getItem("userId") : "",
        token: localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : "",
        ...action.data,
      }
    );
    if (response.data.success) {
      yield put(saveNftCollectionsItemsSuccess(response.data.data));
    } else {
      yield put(saveNftCollectionsItemsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(saveNftCollectionsItemsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}



export default function* pageSaga() {
  yield all([
    yield takeLatest(
      NFT_USER_PROFILE_ITEMS_LIST_START,
      nftUserProfileItemsListAPI
    ),
    yield takeLatest(
      LOAD_MORE_USER_PROFILE_ITEMS_LIST_START,
      loadMoreUserProfileItemAPI
    ),
    yield takeLatest(
      FETCH_NFT_COLLECTIONS_LIST_START,
      fetchNftCollectionsListAPI
    ),
    yield takeLatest(
      SAVE_NFT_COLLECTIONS_ITEMS_START,
      saveNftCollectionsItemsAPI
    ),

  ]);
}
