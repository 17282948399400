import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Image,
  Container,
  Tab,
  Nav,
  Form,
  InputGroup,
  Button,
  FormGroup,
  FormControl,
} from "react-bootstrap";
import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumberIntl, isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input'
import { Link } from "react-router-dom";
import { sendEmailInvitationStart } from "../../store/actions/WalletAppAction";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";


const InviteFriends = (props) => {

  const formRef = useRef(null);

  const [skipRender, setSkipRender] = useState(true);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    mobile: Yup.string().required("Required"),
  });

  const validateMobileNumber = (value) => {
    if (value) {
      if (!isPossiblePhoneNumber(value)) {
        formRef.current.setFieldError("mobile", "Not a Phone number");
        return false;
      } else if (!isValidPhoneNumber(value)) {
        formRef.current.setFieldError("mobile", "Invalid Phone number");
        return false;
      } else {
        formRef.current.setFieldError("mobile", "");
        return true;
      }
    } else {
      formRef.current.setFieldError("mobile", "Required");
      return false;
    }
  };

  const handleSubmit = (values) => {
    const intlNo = formatPhoneNumberIntl(values.mobile);
    const countryCode = intlNo.substring(
      intlNo.indexOf("+") + 1,
      intlNo.indexOf(" ")
    );
    const mobile = intlNo
      .substring(intlNo.indexOf(" "), intlNo.length)
      .replaceAll(" ", "");
    props.dispatch(
      sendEmailInvitationStart({
        ...values,
        mobile: mobile,
        country_code: countryCode,
      })
    );
  };

  useEffect(() => {
    if (!skipRender && !props.sendEmailInvitation.loading && Object.keys(props.sendEmailInvitation.data).length > 0) {
      formRef.current.resetForm();
    }
    setSkipRender(false);
  }, [props.sendEmailInvitation]);

  return (
    <>
      <div className="header-back-btn">
        <Button onClick={() => props.setStep(6)}>
          <Image
            src={
              window.location.origin +
              "/assets/images/onboarding-icons/back.svg"
            }
            className="back-btn"
          />
        </Button>
      </div>

      <div className="create-link-sec login-auth-sec ">
        <div className="sign-in-sec">
          <div className="invite-link-section">
            <div className="mail-box-img text-center mb-5">
              <Image
                src={window.location.origin + "/assets/images/auth/mailbox.svg"}
              />
            </div>
            <h4 className="onboard-link-title">
              Your friends aren't here yet?
            </h4>
            <p>
              Invite your friends and get 100 points for every invited who
              joined
            </p>
            <div className="invite-link-form">
              <Formik
                initialValues={{
                  email: "",
                  mobile: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => handleSubmit(values)}
                innerRef={formRef}
              >
                {({ errors, values, touched, setFieldValue }) => (
                  <FORM className="auth-form">
                    <div className="invite-link-title">
                      <h4>Send invitation via;</h4>
                    </div>
                    <FormGroup className="mb-3">
                      <Form.Label>E-mail*</Form.Label>
                      <InputGroup className={`mb-3 ${touched.email && errors.email ? "is-invalid" : ""
                        }`}>
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            <Image
                              src={
                                window.location.origin + "/assets/images/auth/mail.svg"
                              }
                            />
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Field
                          className={`form-control ${touched.email && errors.email ? "is-invalid" : ""
                            }`}
                          type="email"
                          placeholder="Enter Your Email Address"
                          name="email"
                        />
                      </InputGroup>
                      <ErrorMessage
                        component={"div"}
                        name="email"
                        className="text-danger small"
                      />
                    </FormGroup>
                    <FormGroup className={`mb-3 ${touched.mobile && errors.mobile ? "is-invalid" : ""
                      }`}>
                      <Form.Label>Phone*</Form.Label>
                      <PhoneInput
                        defaultCountry="US"
                        placeholder="Enter phone number"
                        value={values.mobile}
                        onChange={(value) => setFieldValue("mobile", value)}
                        onBlur={() => validateMobileNumber(values.mobile)}
                        international
                        countryCallingCodeEditable={false}
                        className={`${touched.mobile && errors.mobile ? "is-invalid" : ""
                          }`}
                      />
                      <ErrorMessage
                        component={"div"}
                        name="mobile"
                        className="text-danger small"
                      />
                    </FormGroup>
                    <div className="onboard-btn-sec">
                      <Button
                        className="onboard-btn"
                        type="submit"
                        disabled={props.sendEmailInvitation.buttonDisable}
                      >
                        {props.sendEmailInvitation.loadingButtonContent
                          ? props.sendEmailInvitation.loadingButtonContent
                          : "Invite"}
                      </Button>
                    </div>
                  </FORM>
                )}
              </Formik>
            </div>
          </div>

          <div className="home-page-link mt-4 text-center">
            <Link to="/home">Go To Homepage</Link>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  sendEmailInvitation: state.walletApp.sendEmailInvitation,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(InviteFriends);
