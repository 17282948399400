import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, Col, Image, InputGroup, Button, FormControl } from "react-bootstrap";
import { translate, t } from "react-multi-lang";
import configuration from "react-global-configuration";
import InfiniteScroll from "react-infinite-scroll-component";
import NoDataFound from "../../NoDataFound/NoDataFound";
import Skeleton from "react-loading-skeleton";

const ProfileNftItems = (props) => {

	return (
		<Col sm={12}>
			<div className="new-profile-item-header-sec">
				<h4>
					{!props.userNfts.loading && Object.keys(props.userNfts.data).length > 0 && (
						<>Total Items - <span>{props.userNfts.data.filter_total}</span></>
					)}
				</h4>
				<div className="new-profile-item-btn-sec">
					<div className="new-explore-search-sec">
						<div className="new-explore-search-card">
							<Form>
								<InputGroup className="mb-0">
									<InputGroup.Text>
										<Image
											className="new-explore-search-icon"
											src={
												window.location.origin + "/assets/images/new-home/icon/search.svg"
											}
										/>
									</InputGroup.Text>
									<FormControl placeholder="Search Items" value={props.searchNft} onChange={e => props.setSearchNft(e.target.value)} />
									{props.searchNft.length > 0 && (
										<InputGroup.Text className="padding-zero">
											<Button className="search-go-btn" type="button" onClick={e => props.setSearchNft("")}>
												Clear
											</Button>
										</InputGroup.Text>
									)}
								</InputGroup>
							</Form>
						</div>
					</div>
				</div>
			</div>
			{props.userNfts.loading ? (
				<div className="profile-all-post-box">
					{[...Array(8)].map((i) => (
						<Skeleton key={i} className="profile-post-card-loader" />
					))}
				</div>
			) : (
				<>
					{props.userNfts.data.nft_collection_items.length > 0 ? (
						<>
							<InfiniteScroll
								dataLength={
									props.userNfts.data.nft_collection_items
										.length
								}
								next={props.loadMoreUserNfts}
								hasMore={
									props.userNfts.data.nft_collection_items
										.length < props.userNfts.data.filter_total
								}
								loader={
									<div className="profile-all-post-box">
										{[...Array(4)].map((i) => (
											<Skeleton key={i} className="profile-post-card-loader" />
										))}
									</div>
								}
								style={{ height: "auto", overflow: "hidden" }}
							>
								<div className="nft-collection-box">
									{props.userNfts.data.nft_collection_items.map((nft, i) =>
										<div className="nft-collection-card" key={i}>
											<div className="nft-collection-img-sec">
												<Image
													className="nft-collection-img"
													src={nft.picture}
												/>
											</div>
											<div className="nft-collection-info">
												<h4>{nft.nft_collection.name}</h4>
												<p>{nft.name}</p>
											</div>
											<div className="nft-collection-action-btn-sec">
												<Image
													className="nft-collection-icon"
													src={
														window.location.origin +
														"/assets/images/nft-collection/chain-logo-light.png"
													}
												/>
												{nft.recent_buyer_id != props.profile.user_id && (
													<a
														href={`${configuration.get("configData.metaocean_frontend_url")}/assets/${nft.nft_collection.blockchain_type}/${nft.nft_collection.nft_collection_unique_id}/${nft.nft_collection.contract_address}/${nft.token_id}`}
														target="_blank"
														className="nft-collection-buy-now-btn"
													>
														Buy Now
													</a>
												)}
											</div>
										</div>
									)}
								</div>
							</InfiniteScroll>
						</>
					) : (
						<NoDataFound />
					)}
				</>
			)}
		</Col>
	);
};

export default translate(ProfileNftItems);
