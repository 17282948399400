import React, { useState, useEffect, useRef } from "react";
import "./OnBoarding.css";
import {
  Row,
  Col,
  Image,
  Container,
  Tab,
  Nav,
  Form,
  InputGroup,
  Button,
  FormGroup,
  FormControl,
} from "react-bootstrap";
import { Link, useLocation, useHistory } from "react-router-dom";
import LoginSlider from "./LoginSlider";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { resetPasswordStart } from "../../store/actions/UserAction";


const ResetPasswordIndex = (props) => {
  const history = useHistory();

  const [skipRender, setSkipRender] = useState(true);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const resetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required *"),
    password_confirmation: Yup.string()
      .required("Confirm password is required *")
      .when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Both password need to be the same"
        )
      }),
  });

  const handleChange = (type) => {
    if (type === "password") {
      setPasswordVisible(!passwordVisible);
    }
    if (type === "confirm") {
      setConfirmPasswordVisible(!confirmPasswordVisible)
    }
  };

  const handleForgotPasswordSubmit = (values) => {
    let newValues = {
      ...values,
      reset_token: props.match.params.token
    }
    props.dispatch(resetPasswordStart(newValues));
  };

  const handlePasswordValidation = (value) => {

    let error;
    if (!value) {
      error = "Required";
    } else if (value.length < 6) {
      error = "At least 6 letters";
    } else {
      let trimmed = value.replaceAll(" ", "");
      if (trimmed !== value) {
        error = "No space allowed";
      }
    }
    return error;

  };

  useEffect(() => {
    if (!skipRender &&
      !props.inputData.loading &&
      Object.keys(props.inputData.data).length > 0) {
      history.push("/home");
    }
    setSkipRender(false);
  }, [props.inputData]);

  return (
    <>
      <div className="authentication-sec">
        <Container>
          <div className="autentication-box">
            <Row className="align-items-center">
              <Col md={6} className="border-right">
                <LoginSlider />
              </Col>
              <Col md={6} className="border-left">
                <div className="login-auth-sec">

                  <Formik
                    initialValues={{
                      password: "",
                      password_confirmation: "",
                    }}
                    validationSchema={resetPasswordSchema}
                    onSubmit={(values) => handleForgotPasswordSubmit(values)}>

                    {({ touched, errors, isSubmitting, setFieldValue }) => (
                      <FORM className="auth-form">
                        <h4 className="reset-heading">Reset Password</h4>
                        <div className="sign-in-sec">
                          <FormGroup className="mb-4">
                            <InputGroup className={`${touched.password && errors.password
                              ? "is-invalid"
                              : ""
                              }`}>
                              <InputGroup.Prepend>
                                <InputGroup.Text>
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/assets/images/auth/lock.svg"
                                    }
                                  />
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <Field
                                className={`form-control ${touched.password && errors.password
                                  ? "is-invalid"
                                  : ""
                                  }`}
                                type={passwordVisible ? "text" : "password"}
                                placeholder="Enter Your Password"
                                name="password"
                                validate={handlePasswordValidation}
                              />
                              <InputGroup.Append>
                                <InputGroup.Text
                                  onClick={() => setPasswordVisible(!passwordVisible)}
                                >
                                  <Image
                                    src={
                                      passwordVisible
                                        ? window.location.origin +
                                        "/assets/images/auth/eye_dash.svg"
                                        : window.location.origin +
                                        "/assets/images/auth/eye.svg"
                                    }
                                  />
                                </InputGroup.Text>
                              </InputGroup.Append>
                            </InputGroup>
                            <ErrorMessage
                              component={"div"}
                              name="password"
                              className="text-danger small"
                            />
                          </FormGroup>
                          <FormGroup className="mb-4">
                            <InputGroup className={`${touched.password_confirmation && errors.password_confirmation
                              ? "is-invalid"
                              : ""
                              }`}>
                              <InputGroup.Prepend>
                                <InputGroup.Text>
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/assets/images/auth/lock.svg"
                                    }
                                  />
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <Field
                                className={`form-control ${touched.password_confirmation && errors.password_confirmation
                                  ? "is-invalid"
                                  : ""
                                  }`}
                                type={confirmPasswordVisible ? "text" : "password"}
                                placeholder="Enter Your Password"
                                name="password_confirmation"
                              />
                              <InputGroup.Append>
                                <InputGroup.Text
                                  onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                                >
                                  <Image
                                    src={
                                      confirmPasswordVisible
                                        ? window.location.origin +
                                        "/assets/images/auth/eye_dash.svg"
                                        : window.location.origin +
                                        "/assets/images/auth/eye.svg"
                                    }
                                  />
                                </InputGroup.Text>
                              </InputGroup.Append>
                            </InputGroup>
                            <ErrorMessage
                              component={"div"}
                              name="password_confirmation"
                              className="text-danger small"
                            />
                          </FormGroup>
                          <div className="onboard-btn-sec">
                            <Button
                              className="onboard-btn"
                              type="submit"
                              disabled={props.inputData.buttonDisable}
                            >
                              {props.inputData.loadingButtonContent
                                ? props.inputData.loadingButtonContent
                                : "Reset Password"
                              }
                            </Button>
                          </div>
                          <div className="sign-up-link">
                            <Link to="/">Sign In</Link>
                            <Link to="/register">Sign Up</Link>
                          </div>
                        </div>
                      </FORM>
                    )}
                  </Formik>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  inputData: state.users.resetPasswordInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(ResetPasswordIndex);