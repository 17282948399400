import React, { useEffect, useState } from "react";
import HeaderIndex from "./Header/HeaderIndex";
import { Notify } from "react-redux-notify";
import LatestFooter from "./Footer/LatestFooter";
import { connect } from "react-redux";
import { fetchUserDetailsStart } from "../../store/actions/UserAction";
import { useHistory } from 'react-router-dom';

const MainLayout = (props) => {
  let history = useHistory();

  return (
    <div className="app-admin-wrap layout-sidebar-large onboard-layout">
    <Notify position="TopRight" />
    <div className="main-content-wrap sidenav-open d-flex flex-column">
        <div className="main-wrap-sec">
        {React.cloneElement(props.children)}
        </div>
    </div>
    </div>
  );
}

const mapStateToPros = (state) => ({
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros, mapDispatchToProps
)(MainLayout);
