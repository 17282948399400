import React, { useState, useEffect } from "react";
import "./OnBoarding.css";
import {
  Row,
  Col,
  Image,
  Container,
  Tab,
  Nav,
  Form,
  InputGroup,
  Button,
  FormGroup,
  FormControl
} from "react-bootstrap";
import Select from "react-select";
import { Link } from "react-router-dom";
import DatePicker from 'react-date-picker';
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { updateOnboardingStart } from "../../store/actions/WalletAppAction";
import { connect } from "react-redux";
import dayjs from "dayjs";



const AdditionalInformation = (props) => {

  const [skipRender, setSkipRender] = React.useState(true);

  function getRandomNumber(min, max) {
    return Math.round(Math.random() * (max - min) + min);
  }

  const validationSchema = Yup.object().shape({
    education: Yup.string().required("Education is required *"),
  });

  const initialValue = props.profile.data.dob
    ? new Date(props.profile.data.dob)
    : new Date("2000/01/01");

  const requestAbortController = React.useRef(null);
  const [value, setValue] = React.useState(initialValue);

  const genders = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "others", label: "Others" },
    { value: "rather-not-select", label: "Rather Not Select" },
  ];

  const [selectedGender, setSelectedGender] = React.useState();

  useEffect(() => {
    setSelectedGender(
      genders.filter(
        (gender) => gender.value === props.profile.data.gender
      )[0]
    );
  }, []);

  const handleSubmit = (values) => {
    props.dispatch(
      updateOnboardingStart({
        gender: selectedGender.value,
        dob: dayjs(value).format("YYYY-MM-DD"),
        ...values,
      })
    );
  };

  useEffect(() => {
    if (
      !skipRender &&
      !props.onboardingUpdate.loading &&
      Object.keys(props.onboardingUpdate.data).length > 0
    ) {
      props.nextStep();
    }
    setSkipRender(false);
  }, [props.onboardingUpdate]);

  return (
    <>
      <div className="header-back-btn">
        <Button onClick={() => props.prevStep()}>
          <Image
            src={window.location.origin + "/assets/images/onboarding-icons/back.svg"}
            className="back-btn"
          />
        </Button>
      </div>

      <div className="create-link-sec login-auth-sec ">
        <div className="sign-in-sec">
          <Formik
            initialValues={{
              education: props.profile.data.education
                ? props.profile.data.education
                : "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({ touched, errors, isSubmitting, setFieldValue }) => (
              <FORM className="auth-form">
                <div className="forgot-password-content">
                  <h4>Additional Information</h4>
                </div>
                <div className="additional-info">
                  <div className="mt-4">
                    <Form.Group>
                      <Form.Label>Gender*</Form.Label>
                      <Select
                        options={genders}
                        initialValue={selectedGender}
                        value={selectedGender}
                        onChange={(value) => setSelectedGender(value)}
                      />
                    </Form.Group>
                  </div>
                  <div className="mt-4">
                    <Form.Label>Birthday*</Form.Label>
                    <div className=" additional-date-picker">
                      {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={value}
                          loading={isLoading}
                          onChange={(newValue) => {
                            setValue(newValue);
                          }}
                          onMonthChange={handleMonthChange}
                          renderInput={(params) => <TextField {...params} />}
                          renderLoading={() => <CalendarPickerSkeleton />}
                          renderDay={(day, _value, DayComponentProps) => {
                            const isSelected =
                              !DayComponentProps.outsideCurrentMonth &&
                              highlightedDays.indexOf(day.date()) > 0;

                            return (
                              <Badge
                                key={day.toString()}
                                overlap="circular"
                                badgeContent={isSelected ? "🌚" : undefined}
                              >
                                <PickersDay {...DayComponentProps} />
                              </Badge>
                            );
                          }}
                        />
                      </LocalizationProvider> */}
                      <DatePicker onChange={newValue => setValue(newValue)} value={value} />
                    </div>
                  </div>

                  <div className="mt-4 mb-4">
                    <Form.Group className={`mb-3 ${touched.education && errors.education ? "is-invalid" : ""
                      }`}>
                      <Form.Label>Education*</Form.Label>
                      <Field
                        className={`form-control ${touched.education && errors.education ? "is-invalid" : ""
                          }`}
                        name="education"
                      />
                      <ErrorMessage component={"div"} name="education" className='text-danger text-right' />
                    </Form.Group>
                  </div>
                </div>

                <div className="onboard-btn-sec mt-5">
                  <Button
                    type="submit"
                    className="onboard-btn"
                    disabled={!value || props.onboardingUpdate.buttonDisable}>
                    {props.onboardingUpdate.loadingButtonContent
                      ? props.onboardingUpdate.loadingButtonContent
                      : "Continue"}
                  </Button>
                </div>
              </FORM>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  onboardingUpdate: state.walletApp.onboardingUpdate,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AdditionalInformation);