import React from "react";
import { Form, Button, Image, Modal, Container, Row, Col, InputGroup, FormControl } from "react-bootstrap";

const AddPropertiesModal = (props) => {
  return (
    <>
      <Modal
        className="modal-dialog-center add-properties-modal"
        size="md"
        centered
        show={props.addProperties}
        onHide={props.closeAddPropertiesModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Properties</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{
          maxHeight: 'calc(100vh - 210px)',
          overflowY: 'auto'
        }}>
          <p>Properties show up underneath your item, are clickable, and can be filtered in your collection's sidebar.</p>
          <Form className="create-new-item-form edit-profile-form">
            {props.properties.map((property, i) =>
              <Row>
                <Col md={6}>
                  <Form.Label>Property</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon1">
                        <div className="properties-collapse-btn-sec">
                          <Button className="properties-collapse-btn" onClick={() => props.handlePropertyDelete(property)}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" /></svg>
                          </Button>
                        </div>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      placeholder=""
                      value={property.name}
                      onChange={e => props.handlePropertyNameChange(i, e.target.value)}
                    />
                  </InputGroup>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Value</Form.Label>
                    <Form.Control type="text"
                      placeholder=""
                      value={property.value}
                      onChange={(event) => props.handlePropertyValueChange(i, event.currentTarget.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}
            <div className="add-more-btn-sec">
              <Button onClick={() => props.handleNewProperty()}>Add More</Button>
            </div>
            <div className="save-btn-sec">
              <Button onClick={() => props.handlePropertySave()}> Save</Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddPropertiesModal;