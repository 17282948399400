import React, { useState, useEffect } from "react";
import "./OnBoarding.css";
import {
  Row,
  Col,
  Image,
  Container,
  Tab,
  Nav,
  Form,
  InputGroup,
  Button,
  FormGroup,
  FormControl,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import {
  updateOnboardingStart,
  usernameValidationStart,
} from "../../store/actions/WalletAppAction";
import configuration from "react-global-configuration";

const CreateYourLink = (props) => {
  const [isvalidUserName, setIsValidUserName] = useState(false);
  const [userName, setUserName] = useState(props.profile.data.username);
  const [skipRender, setSkipRender] = useState(true);
  const [formattedLink, setFormattedLink] = useState(
    configuration.get("configData.frontend_url").replace("https://", "")
  );

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Username is required *"),
  });

  const handleUsernameValidation = (username) => {
    if (username && username.length > 3) {
      if (username.replace(" ", "") === username) {
        if (username !== userName) {
          setUserName(username);
          setIsValidUserName(true);
          props.dispatch(usernameValidationStart({ username: username }));
          return "";
        }
      } else {
        setIsValidUserName(false);
        return "No white space allowed";
      }
    } else {
      setIsValidUserName(false);
      return "Must Contain 4 Characters";
    }
  };

  const handleSubmit = (values) => {
    props.dispatch(updateOnboardingStart(values));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !props.onboardingUpdate.loading &&
      Object.keys(props.onboardingUpdate.data).length > 0
    ) {
      props.nextStep();
    }
    setSkipRender(false);
  }, [props.onboardingUpdate]);

  return (
    <>
      <div className="create-link-sec login-auth-sec">
        <div className="sign-in-sec">
          <Formik
            initialValues={{
              username: props.profile.data.username,
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({ touched, errors, isSubmitting, setFieldValue }) => (
              <FORM className="auth-form">
                <div className="forgot-password-content">
                  <h4>Create Your Link</h4>
                  <p>
                    The more link powerful, the more <br /> you are.
                  </p>
                </div>
                <FormGroup>
                  <div className="create-link-details">
                    <InputGroup
                      className={`mb-0 ${
                        touched.username && errors.username ? "is-invalid" : ""
                      }`}
                    >
                      <InputGroup.Text id="basic-addon1">
                        <Image
                          src={
                            window.location.origin +
                            "/assets/images/onboarding-icons/link.svg"
                          }
                        />
                      </InputGroup.Text>
                      <InputGroup.Text className="input-group-icon username-link">
                        {formattedLink}
                      </InputGroup.Text>

                      <Field
                        className={`form-control username-field 
                        ${
                          touched.username && errors.username
                            ? "is-invalid"
                            : ""
                        }`}
                        name="username"
                        validate={handleUsernameValidation}
                        autoComplete="off"
                      />
                    </InputGroup>
                  </div>
                  <ErrorMessage
                    component={"div"}
                    name="username"
                    className="text-danger text-right"
                  />
                  {props.validation.isInValid && isvalidUserName ? (
                    <div class="text-danger text-right">
                      Name is already used.
                    </div>
                  ) : (
                    ""
                  )}
                  {props.validation.isValid && isvalidUserName ? (
                    <div class="text-success text-right">Name is available</div>
                  ) : (
                    ""
                  )}
                </FormGroup>
                <div className="onboard-btn-sec mt-4">
                  <Button
                    type="submit"
                    className="onboard-btn"
                    disabled={
                      !(
                        (props.validation.isValid && isvalidUserName) ||
                        props.profile.data.username === userName
                      ) || props.onboardingUpdate.buttonDisable
                    }
                  >
                    {props.onboardingUpdate.loadingButtonContent
                      ? props.onboardingUpdate.loadingButtonContent
                      : "Continue"}
                  </Button>
                </div>
              </FORM>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  validation: state.walletApp.validationInputData,
  onboardingUpdate: state.walletApp.onboardingUpdate,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(CreateYourLink);
