import {
  FETCH_ONBOARDING_CATEGORIES_FAILURE,
  FETCH_ONBOARDING_CATEGORIES_START,
  FETCH_ONBOARDING_CATEGORIES_SUCCESS,
  REGENERATE_MOBILE_VERIFICATION_CODE_FAILURE,
  REGENERATE_MOBILE_VERIFICATION_CODE_START,
  REGENERATE_MOBILE_VERIFICATION_CODE_SUCCESS,
  SEND_EMAIL_INVITAION_FAILURE,
  SEND_EMAIL_INVITAION_START,
  SEND_EMAIL_INVITAION_SUCCESS,
  UPDATE_ONBOARDING_CATEGORIES_FAILURE,
  UPDATE_ONBOARDING_CATEGORIES_START,
  UPDATE_ONBOARDING_CATEGORIES_SUCCESS,
  UPDATE_ONBOARDING_FAILURE,
  UPDATE_ONBOARDING_START,
  UPDATE_ONBOARDING_SUCCESS,
  ONBOARDING_USERNAME_VALIDATION_FAILURE,
  ONBOARDING_USERNAME_VALIDATION_START,
  ONBOARDING_USERNAME_VALIDATION_SUCCESS,
  VERIFY_MOBILE_FAILURE,
  VERIFY_MOBILE_START,
  VERIFY_MOBILE_SUCCESS
} from "../actions/ActionConstant";


const initialState = {
  verifyMobile: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  regenerateMobileVerification: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  onboardingUpdate: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  validationInputData: {
    data: {},
    loading: true,
    error: false,
    isValid: false,
    isInValid: false,
  },
  onboardingCategories: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  updateOnboardingCategories: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  sendEmailInvitation: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  }
};

const walletAppReducer = (state = initialState, action) => {
  switch (action.type) {
    case VERIFY_MOBILE_START:
      return {
        ...state,
        verifyMobile: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        },
      };
    case VERIFY_MOBILE_SUCCESS:
      return {
        ...state,
        verifyMobile: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case VERIFY_MOBILE_FAILURE:
      return {
        ...state,
        verifyMobile: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case REGENERATE_MOBILE_VERIFICATION_CODE_START:
      return {
        ...state,
        regenerateMobileVerification: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case REGENERATE_MOBILE_VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
        regenerateMobileVerification: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case REGENERATE_MOBILE_VERIFICATION_CODE_FAILURE:
      return {
        ...state,
        regenerateMobileVerification: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case UPDATE_ONBOARDING_START:
      return {
        ...state,
        onboardingUpdate: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case UPDATE_ONBOARDING_SUCCESS:
      return {
        ...state,
        onboardingUpdate: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case UPDATE_ONBOARDING_FAILURE:
      return {
        ...state,
        onboardingUpdate: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case ONBOARDING_USERNAME_VALIDATION_START:
      return {
        ...state,
        validationInputData: {
          data: action.data,
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading please wait",
        },
      };

    case ONBOARDING_USERNAME_VALIDATION_SUCCESS:
      return {
        ...state,
        validationInputData: {
          data: {},
          loading: false,
          error: false,
          isValid: true,
          isInValid: false,
        },
      };
    case ONBOARDING_USERNAME_VALIDATION_FAILURE:
      return {
        ...state,
        validationInputData: {
          data: {},
          loading: false,
          error: action.data,
          isInValid: true,
          isValid: false,
        },
      };
    case FETCH_ONBOARDING_CATEGORIES_START:
      return {
        ...state,
        onboardingCategories: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case FETCH_ONBOARDING_CATEGORIES_SUCCESS:
      return {
        ...state,
        onboardingCategories: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case FETCH_ONBOARDING_CATEGORIES_FAILURE:
      return {
        ...state,
        onboardingCategories: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };

    case UPDATE_ONBOARDING_CATEGORIES_START:
      return {
        ...state,
        updateOnboardingCategories: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case UPDATE_ONBOARDING_CATEGORIES_SUCCESS:
      return {
        ...state,
        updateOnboardingCategories: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case UPDATE_ONBOARDING_CATEGORIES_FAILURE:
      return {
        ...state,
        updateOnboardingCategories: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case SEND_EMAIL_INVITAION_START:
      return {
        ...state,
        sendEmailInvitation: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case SEND_EMAIL_INVITAION_SUCCESS:
      return {
        ...state,
        sendEmailInvitation: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case SEND_EMAIL_INVITAION_FAILURE:
      return {
        ...state,
        sendEmailInvitation: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    default:
      return state;
  }
}

export default walletAppReducer;