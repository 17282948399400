import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Image,
  Container,
  Tab,
  Nav,
  Form,
  InputGroup,
  Button,
  FormGroup,
  FormControl,
} from "react-bootstrap";
import GoogleMapReact from 'google-map-react';
import Map, { GoogleApiWrapper, Marker } from "google-maps-react";
import { connect } from "react-redux";
import { apiConstants } from "../Constant/constants";
import { updateOnboardingStart } from "../../store/actions/WalletAppAction";

const LocationSec = (props) => {

  const [skipRender, setSkipRender] = useState(true);
  const [mapPosition, setMapPosition] = useState('');
  const [locationData, setLocationData] = useState({
    latitude: props.profile.data.latitude ? props.profile.data.latitude : 37,
    longitude: props.profile.data.longitude ? props.profile.data.longitude : -95,
    address: props.profile.data.address ? props.profile.data.address : "",
  });

  let autocomplete;

  const renderAutoComplete = () => {
    const { google } = props;
    if (!google) {
      console.log("asdfsadfasdfno");
      return;
    }

    autocomplete = new google.maps.places.Autocomplete(autocomplete, {
      types: ["geocode"]
    });

    autocomplete.setFields(["address_component", "geometry", "name"]);

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      console.log("Place", place);
      if (!place || !place.geometry) return;

      setMapPosition(place.geometry.location);


      let full_address = "";
      place.address_components.map(
        (address) =>
        (full_address =
          full_address == ""
            ? address.long_name
            : full_address + "," + address.long_name)
      );

      setLocationData({
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        address: full_address,
      });
    });
  };

  const handleSubmit = () => {
    props.dispatch(updateOnboardingStart(locationData));
  }

  useEffect(() => {
    if (!skipRender && !props.onboardingUpdate.loading && Object.keys(props.onboardingUpdate.data).length > 0) {
      props.nextStep();
    }
    setSkipRender(false);
  }, [props.onboardingUpdate]);

  const AnyReactComponent = ({ text }) => (
    <div style={{
      color: 'white',
      background: 'grey',
      padding: '15px 10px',
      display: 'inline-flex',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '100%',
      transform: 'translate(-50%, -50%)'
    }}>
      {text}
    </div>
  );

  return (
    <>
      <div className="header-back-btn">
        <Button onClick={() => props.prevStep()}>
          <Image
            src={
              window.location.origin +
              "/assets/images/onboarding-icons/back.svg"
            }
            className="back-btn"
          />
        </Button>
      </div>

      <div className="create-link-sec login-auth-sec ">
        <div className="sign-in-sec">
          <Form className="auth-form">
            <div className="forgot-password-content">
              <h4>Location</h4>
            </div>
            <FormGroup className="mb-5 mt-5">
              <InputGroup className="mb-3 mt-5">
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon1">
                    <Image
                      src={
                        window.location.origin + "/assets/images/auth/mail.svg"
                      }
                    />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  placeholder={props.profile.data.address ? props.profile.data.address : "Search Items, Collections and Accounts"}
                  onFocus={renderAutoComplete}
                  ref={ref => (autocomplete = ref)}
                />
              </InputGroup>
            </FormGroup>
            <div style={{ height: '50vh', width: '100%', marginBottom: '3em' }}>
              <Map
                className="new-map"
                google={props.google}
                visible={true}
                initialCenter={{
                  lat: locationData.latitude,
                  lng: locationData.longitude,
                }}
                center={mapPosition}
                zoom={8}
              >
                <Marker position={mapPosition} />
              </Map>
              {/* <GoogleMapReact
                bootstrapURLKeys={{ key: "" }}
                defaultCenter={{
                  lat: locationData.latitude,
                  lng: locationData.longitude
                }}
                defaultZoom={11}
              >
                <AnyReactComponent
                  lat={locationData.latitude}
                  lng={locationData.longitude}
                  text="My Marker"
                />
              </GoogleMapReact> */}
            </div>
            <div className="onboard-btn-sec mt-4">
              <Button
                className="onboard-btn"
                disabled={!locationData.address || props.onboardingUpdate.buttonDisable}
                onClick={() => handleSubmit()}>
                {props.onboardingUpdate.loadingButtonContent
                  ? props.onboardingUpdate.loadingButtonContent
                  : "Continue"}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  onboardingUpdate: state.walletApp.onboardingUpdate,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

const connector = connect(mapStateToPros, mapDispatchToProps)(LocationSec);

export default GoogleApiWrapper({
  apiKey: apiConstants.google_api_key
})(connector);
