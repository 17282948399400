import { all, fork } from "redux-saga/effects";

import UserSaga from "./UserSaga";
import ChangePasswordSaga from "./ChangePasswordSaga";
import SubscriptionSaga from "./SubscriptionSaga";
import CardsSaga from "./CardsSaga";
import BankAccountSaga from "./BankAccountSaga";
import KycDocumentSaga from "./KycDocumentSaga";
import WalletSaga from "./WalletSaga";
import TransactionSaga from "./TransactionSaga";
import WithDrawSaga from "./WithDrawSaga";
import PageSaga from "./PageSaga";
import PostSaga from "./PostSaga";
import ErrorSaga from "./ErrorSaga";
import FollowSaga from "./FollowSaga";
import VerificationDocumentSaga from "./VerificationDocumentSaga";
import CommentsSaga from "./CommentsSaga";
import FavSaga from "./FavSaga";
import BookmarkSaga from "./BookmarkSaga";
import SendTipSaga from "./SendTipSaga";
import HomeSaga from "./HomeSaga";
import OtherUserSaga from "./OtherUserSaga";
import PostLikesSaga from "./PostLikesSaga";
import ChatSaga from "./ChatSaga";
import NotificationSaga from "./NotificationSaga";
import VideoCallSaga from "./VideoCallSaga";
import LiveVideoSaga from "./LiveVideoSaga";
import PrivateCallSaga from "./PrivateCallSaga";
import CategorySaga from "./CategorySaga";
import ReferralSaga from "./ReferralSaga";
import ChatAssetSaga from "./ChatAssetSaga";
import UserCategorySaga from './UserCategorySaga'
import StoriesSaga from './StoriesSaga'
import ProductOwnerSaga from './ProductOwnerSaga'
import ProductsSaga from './ProductsSaga'
import VodVideosSaga from './VodVideosSaga'
import SessionSaga from "./SessionSaga"
import NftSaga from "./NftSaga"
import WalletAppSaga from './WalletAppSaga';

export default function* rootSaga() {
  yield all([
    fork(UserSaga),
    fork(ChangePasswordSaga),
    fork(SubscriptionSaga),
    fork(CardsSaga),
    fork(BankAccountSaga),
    fork(KycDocumentSaga),
    fork(WalletSaga),
    fork(TransactionSaga),
    fork(WithDrawSaga),
    fork(ErrorSaga),
    fork(PageSaga),
    fork(PostSaga),
    fork(FollowSaga),
    fork(VerificationDocumentSaga),
    fork(CommentsSaga),
    fork(FavSaga),
    fork(BookmarkSaga),
    fork(SendTipSaga),
    fork(HomeSaga),
    fork(OtherUserSaga),
    fork(PostLikesSaga),
    fork(ChatSaga),
    fork(NotificationSaga),
    fork(VideoCallSaga),
    fork(LiveVideoSaga),
    fork(PrivateCallSaga),
    fork(CategorySaga),
    fork(ReferralSaga),
    fork(ChatAssetSaga),
    fork(UserCategorySaga),
    fork(StoriesSaga),
    fork(ProductOwnerSaga),
    fork(ProductsSaga),
    fork(VodVideosSaga),
    fork(SessionSaga),
    fork(WalletAppSaga),
    fork(NftSaga)
  ]);
}
