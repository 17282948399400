import React, { useState, useEffect } from "react";
import "./OnBoarding.css";
import {
  Row,
  Col,
  Image,
  Container,
  Tab,
  Nav,
  Form,
  InputGroup,
  Button,
  FormGroup,
  FormControl,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import LoginSlider from "./LoginSlider";
import CreateYourLink from "./CreateYourLink";
import CategorySelector from "./CategorySelector";
import AdditionalInformation from "./AdditionalInformation";
import LocationSec from "./LocationSec";
import AccountCompletion from "./AccountCompletion";
import LinkYourAccount from "./LinkYourAccount";
import InviteFriends from "./InviteFriends";
import { connect } from "react-redux";
import { fetchUserDetailsStart } from "../../store/actions/UserAction";

const OnBoardingIndex = (props) => {
  const [step, setStep] = useState(0);
  const [skipRender, setSkipRender] = useState(false);

  useEffect(() => {
    if (Object.keys(props.profile.data).length > 0) {
      setStep(props.profile.data.onboarding_step);
    } else {
      props.dispatch(fetchUserDetailsStart());
    }
  }, []);

  useEffect(() => {
    if (!props.profile.loading && Object.keys(props.profile.data).length > 0) {
      if (!skipRender) {
        setStep(props.profile.data.onboarding_step);
        setSkipRender(true);
      }
    }
  }, [props.profile]);

  const changeStep = (stepNo) => {
    if (stepNo <= props.profile.data.onboarding_step || stepNo > 6) {
      setStep(stepNo);
    }
  }

  const nextStep = () => {
    changeStep(step + 1);
  }

  const prevStep = () => {
    changeStep(step - 1);
  }

  return (
    <>
      <div className="authentication-sec">
        <Container>
          <div className="autentication-box">
            <Row className="align-items-center">
              <Col md={12} xl={6} lg={6} className="border-right resp-order">
                <LoginSlider />
              </Col>
              {props.profile.data ?
                <Col md={12} xl={6} lg={6} className="border-left">
                  {step == 2 && (
                    <div className="step1">
                      <CreateYourLink nextStep={nextStep} profile={props.profile} />
                    </div>
                  )}
                  {step == 3 && (
                    <div className="step2">
                      <CategorySelector prevStep={prevStep} nextStep={nextStep} profile={props.profile} />
                    </div>
                  )}
                  {step == 4 && (
                    <div className="step3">
                      <AdditionalInformation prevStep={prevStep} nextStep={nextStep} profile={props.profile} />
                    </div>
                  )}
                  {step == 5 && (
                    <div className="step4">
                      <LocationSec prevStep={prevStep} nextStep={nextStep} profile={props.profile} />
                    </div>
                  )}
                  {step == 6 && (
                    <div className="step5">
                      <AccountCompletion prevStep={prevStep} setStep={changeStep} profile={props.profile} />
                    </div>
                  )}
                  {step == 7 && (
                    <div className="step5">
                      <LinkYourAccount setStep={changeStep} profile={props.profile} />
                    </div>
                  )}
                  {step == 8 && (
                    <div className="step5">
                      <InviteFriends setStep={changeStep} profile={props.profile} />
                    </div>
                  )}
                </Col>
                : null
              }
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(OnBoardingIndex);
