import React, { useState, useRef } from "react";
import { translate, t } from "react-multi-lang";
import { Button } from "react-bootstrap";
import Persona from "persona";
import configuration from "react-global-configuration";
import { connect } from "react-redux";
import { withdrawalKycStatusUpdateStart } from "../../store/actions/KycDocumentAction";

const WithdrawKycVerification = (props) => {
  const [options, setOptions] = useState({
    templateId: configuration.get("configData.withdrawal_kyc_template_id"),
    environment: configuration.get("configData.withdrawal_kyc_environment"),
    host: configuration.get("configData.withdrawal_kyc_environment"),
  });

  const embeddedClientRef = useRef(null);

  const createClient = () => {
    const client = new Persona.Client({
      ...options,
      onLoad: (error) => {
        if (error) {
          console.error(
            `Failed with code: ${error.code} and message ${error.message}`
          );
        }

        client.open();
      },
      onStart: (inquiryId) => {
        console.log(`Started inquiry ${inquiryId}`);
      },
      onComplete: (data) => {
        console.log(
          `Sending finished inquiry ${data.inquiryId} to backend`,
          data
        );
        props.dispatch(
          withdrawalKycStatusUpdateStart({
            inquiry_id: data.inquiryId,
            kyc_status: data.status,
          })
        );
      },
      onEvent: (name, meta) => {
        switch (name) {
          case "start":
            console.log(`Received event: start`);
            break;
          default:
            console.log(
              `Received event: ${name} with meta: ${JSON.stringify(meta)}`
            );
        }
      },
    });
    embeddedClientRef.current = client;

    window.exit = (force) =>
      client ? client.exit(force) : alert("Initialize client first");
  };

  if (!options.templateId) {
    return null;
  }

  const handleKycModal = () => {
    if (embeddedClientRef.current != null) {
      embeddedClientRef.current.open();
    } else {
      createClient();
    }
  };

  return (
    <>
      <Button className="btn btn-danger mt-4" onClick={handleKycModal}>
        {t("complete_kyc_verification")}
      </Button>
    </>
  );
};

const mapStateToPros = (state) => ({
  withdrawalKyc: state.kycDocument.withdrawalKyc,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(WithdrawKycVerification));
