import {
  NFT_USER_PROFILE_ITEMS_LIST_START,
  NFT_USER_PROFILE_ITEMS_LIST_SUCCESS,
  NFT_USER_PROFILE_ITEMS_LIST_FAILURE,
  LOAD_MORE_USER_PROFILE_ITEMS_LIST_START,
  LOAD_MORE_USER_PROFILE_ITEMS_LIST_SUCCESS,
  LOAD_MORE_USER_PROFILE_ITEMS_LIST_FAILURE,
  FETCH_NFT_COLLECTIONS_LIST_START,
  FETCH_NFT_COLLECTIONS_LIST_SUCCESS,
  FETCH_NFT_COLLECTIONS_LIST_FAILURE,
  SAVE_NFT_COLLECTIONS_ITEMS_START,
  SAVE_NFT_COLLECTIONS_ITEMS_SUCCESS,
  SAVE_NFT_COLLECTIONS_ITEMS_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  userProfileItems: {
    data: [],
    loading: true,
    error: false,
    total: 0,
  },
  nftCollectionsList: {
    data: {},
    loading: true,
    error: false,
  },
  saveNftCollectionsItems: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    loadingButtonContent: null,
    buttonDisable: false,
  },
};

const NftReducer = (state = initialState, action) => {
  switch (action.type) {
    case NFT_USER_PROFILE_ITEMS_LIST_START:
      return {
        ...state,
        userProfileItems: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case NFT_USER_PROFILE_ITEMS_LIST_SUCCESS:
      return {
        ...state,
        userProfileItems: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case NFT_USER_PROFILE_ITEMS_LIST_FAILURE:
      return {
        ...state,
        userProfileItems: {
          data: {},
          loading: false,
          error: false,
        },
      };

    case LOAD_MORE_USER_PROFILE_ITEMS_LIST_START:
      return {
        ...state,
        userProfileItems: {
          ...state.userProfileItems,
          loading: false,
          error: false,
        },
      };
    case LOAD_MORE_USER_PROFILE_ITEMS_LIST_SUCCESS:
      return {
        ...state,
        userProfileItems: {
          ...state.userProfileItems,
          data: {
            ...state.userProfileItems.data,
            nft_collection_items: [
              ...state.userProfileItems.data.nft_collection_items,
              ...action.data.nft_collection_items,
            ],
            filter_total: action.data.filter_total,
            total: action.data.total,
          },
          loading: false,
          error: false,
        },
      };
    case LOAD_MORE_USER_PROFILE_ITEMS_LIST_FAILURE:
      return {
        ...state,
        userProfileItems: {
          ...state.userProfileItems,
          loading: false,
          error: action.error,
        },
      };
    
      case FETCH_NFT_COLLECTIONS_LIST_START:
      return {
        ...state,
        nftCollectionsList: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_NFT_COLLECTIONS_LIST_SUCCESS:
      return {
        ...state,
        nftCollectionsList: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_NFT_COLLECTIONS_LIST_FAILURE:
      return {
        ...state,
        nftCollectionsList: {
          data: {},
          loading: false,
          error: action.error,
        },
      };

      case SAVE_NFT_COLLECTIONS_ITEMS_START:
        return {
          ...state,
          saveNftCollectionsItems: {
            inputData: action.data,
            data: {},
            loading: true,
            error: false,
            loadingButtonContent: "Loading... Please wait",
            buttonDisable: true,
          },
        };
      case SAVE_NFT_COLLECTIONS_ITEMS_SUCCESS:
        return {
          ...state,
          saveNftCollectionsItems: {
            data: action.data,
            loading: false,
            error: false,
            inputData: {},
            loadingButtonContent: null,
            buttonDisable: false,
          },
        };
      case SAVE_NFT_COLLECTIONS_ITEMS_FAILURE:
        return {
          ...state,
          saveNftCollectionsItems: {
            data: {},
            loading: false,
            error: action.error,
            inputData: {},
            loadingButtonContent: null,
            buttonDisable: false,
          },
        };

    default:
      return state;
  }
};

export default NftReducer;
