import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Image,
  Container,
  Tab,
  Nav,
  Form,
  InputGroup,
  Button,
} from "react-bootstrap";
import { createNotification } from "react-redux-notify";
import { Link, useHistory } from "react-router-dom";
import { getSuccessNotificationMessage } from "../helper/NotificationMessage";
import { connect } from "react-redux";
import CopyToClipboard from 'react-copy-to-clipboard';

const ViewPrivateKey = (props) => {
  const history = useHistory();
  const [key, setKey] = useState("");

  useEffect(() => {
    if (
      (Object.keys(props.verifyMobile.data).length > 0 &&
        props.verifyMobile.data.private_passphrase) ||
      (Object.keys(props.registerInputData.data).length > 0 &&
        props.registerInputData.data.data.private_passphrase)
    ) {
      setKey(
        Object.keys(props.verifyMobile.data).length > 0
          ? props.verifyMobile.data.private_passphrase
          : props.registerInputData.data.data.private_passphrase
      );
    } else {
      history.push("/home");
    }
  }, []);

  const onNext = () => {
    history.push("/onboarding");
  }

  const onCopy = () => {
    props.dispatch(createNotification(getSuccessNotificationMessage("Private key copied")));
  };

  return (
    <>
      <div className="authentication-sec">
        <Container>
          <div className="autentication-box">
            <Row className="justify-content-center">
              <Col md={6}>
                <div className="login-auth-sec">
                  <div className="sign-in-sec mt-4">
                    <div className="view-privte-key-sec">
                      <h3>Congrats!</h3>

                      <h4>You are now a part of Marketplace!</h4>
                      <h5>
                        We need more information about you to customize your
                        Marketplace experience.
                      </h5>
                      <p>
                        <span>Warning: </span> Never disclose this key. Anyone
                        with your private keys can steal any assets held in your
                        account. Kindly store it in a secure location.
                      </p>
                    </div>
                    <div className="private-key-content">
                      <h5>Your Key:</h5>
                      <div className="private-key-box">
                        <p>
                          {key}
                        </p>
                        <div className="private-key-btn">
                          <CopyToClipboard text={key}
                            onCopy={() => onCopy()}>
                            <Button>
                              <Image
                                src={
                                  window.location.origin +
                                  "/assets/images/icons/copy.svg"
                                }
                              />
                              <span>Copy</span>
                            </Button>
                          </CopyToClipboard>
                        </div>
                      </div>
                    </div>

                    <div className="login-btn mt-4">
                      <Button
                        className="btn gradient-btn gradientcolor"
                        onClick={() => onNext()}
                      >
                        Let's Start
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  verifyMobile: state.walletApp.verifyMobile,
  registerInputData: state.users.registerInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(ViewPrivateKey);