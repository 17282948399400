import React, { useState, useEffect } from "react";
import "./OnBoarding.css";
import {
  Row,
  Col,
  Image,
  Container,
  Tab,
  Nav,
  Form,
  InputGroup,
  Button,
  FormGroup,
  FormControl
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  fetchOnboardingCategoriesStart,
  updateOnboardingCategoriesStart,
} from "../../store/actions/WalletAppAction";
import Skeleton from "react-loading-skeleton";


const CategorySelector = (props) => {
  const [selectedCategories, setSeletedCategories] = useState([]);
  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    props.dispatch(fetchOnboardingCategoriesStart());
    setSeletedCategories(
      props.profile.data.user_categories.map(
        (category, i) => category.onboarding_category_id
      )
    );
  }, [props.profile]);

  const addCategory = (categoryId) => {
    setSeletedCategories([...selectedCategories, categoryId]);
  };

  const removeCategory = (categoryId) => {
    setSeletedCategories(
      selectedCategories.filter((cat) => cat !== categoryId)
    );
  };

  const handleSubmit = () => {
    if (selectedCategories.length > 0) {
      const data = JSON.stringify(selectedCategories);
      props.dispatch(
        updateOnboardingCategoriesStart({
          onboarding_category_id: data.substring(1, data.length - 1),
        })
      );
    }
  };

  useEffect(() => {
    if (
      !skipRender &&
      !props.updateOnboardingCategories.loading &&
      Object.keys(props.updateOnboardingCategories.data).length > 0
    ) {
      props.nextStep();
    }
    setSkipRender(false);
  }, [props.updateOnboardingCategories]);


  return (
    <>
      <div className="header-back-btn">
        <Button onClick={() => props.prevStep()}>
          <Image
            src={window.location.origin + "/assets/images/onboarding-icons/back.svg"}
            className="back-btn"
          />
        </Button>
      </div>

      <div className="create-link-sec login-auth-sec ">
        <div className="sign-in-sec">
          <Form className="auth-form">
            <div className="forgot-password-content">
              <h4>Category</h4>
              {selectedCategories.length > 0
                ? <p>You choosen {selectedCategories.length} items</p>
                : <p>Please choose at least 1 tag for us to know you better!</p>}
            </div>
            <div className="category-list mb-4">
              {props.onboardingCategories.loading ?
                <ul className="list-unstyled onboard-btn-sec">
                  {[...Array(9)].map((i, j) => (
                    <Skeleton
                      className="category-outline-btn"
                      width={j % 3 === 0 ? 250 : 100}
                    />
                  ))}
                </ul>
                : props.onboardingCategories.data.onboarding_categories &&
                  props.onboardingCategories.data.onboarding_categories.length > 0
                  ? <ul className="list-unstyled onboard-btn-sec">
                    {props.onboardingCategories.data.onboarding_categories.map((category, i) =>
                      <>{
                        selectedCategories.includes(category.onboarding_category_id)
                          ? <li className="active" onClick={() =>
                            removeCategory(category.onboarding_category_id)
                          }>
                            <span>
                              <Image
                                src={
                                  window.location.origin +
                                  "/assets/images/onboarding-icons/close.svg"
                                }
                              />
                            </span>
                            {category.name}
                          </li>
                          : <li onClick={() =>
                            addCategory(category.onboarding_category_id)
                          }>
                            {category.name}
                          </li>
                      }
                      </>
                    )}
                  </ul>
                  : null
              }
            </div>
            <div className="onboard-btn-sec mt-5">
              <Button
                className="onboard-btn"
                disabled={
                  selectedCategories.length === 0 ||
                  props.updateOnboardingCategories.buttonDisable
                }
                onClick={() => handleSubmit()}
              >
                {props.updateOnboardingCategories.loadingButtonContent
                  ? props.updateOnboardingCategories.loadingButtonContent
                  : "Continue"}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  onboardingCategories: state.walletApp.onboardingCategories,
  updateOnboardingCategories: state.walletApp.updateOnboardingCategories,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(CategorySelector);