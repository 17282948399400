import React, { useState, useEffect } from "react";
import {
  Modal,
  Container,
  Row,
  Col,
  Button,
  Form,
  Image,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import "./SocialMediaImport.css";
import { Link } from "react-router-dom";
import AddPropertiesModal from "./AddPropertiesModal";
import { useParams } from "react-router";
import {
  fetchSinglePostStart,
  postNftStatusUpdateStart,
  postsFilesViewBase64Start,
} from "../../store/actions/PostAction";
import {
  fetchNftCollectionsListStart,
  saveNftCollectionsItemsStart,
} from "../../store/actions/NftAction";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import useNftTemplate from "../../hooks/useNftTemplate";
import { Formik, Form as FORM, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import * as htmlToImage from "html-to-image";
import { apiConstants } from "../Constant/constants";
import configuration from "react-global-configuration";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Select from "react-select";

//Declare IPFS
const auth =
  "Basic " +
  Buffer.from(
    apiConstants.ipfs_project_id + ":" + apiConstants.ipfs_project_secret
  ).toString("base64");
const ipfsClient = require("ipfs-http-client");
const ipfs = ipfsClient({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
  // apiPath: '/api/v0',
  headers: {
    authorization: auth,
  },
}); // leaving out the arguments will default to these values

const CreateNewItemIndex = (props) => {
  const params = useParams();
  const history = useHistory();
  const nftTemlate = useNftTemplate();

  const [ipfsStatus, setIpfsStatus] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(true);
  const [skipRender, setSkipRender] = useState(true);
  const [post, setPost] = useState();
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [properties, setProperties] = useState([]);
  const [propertiesSwapper, setPropertiesSwapper] = useState([]);
  const [addProperties, setAddProperties] = useState(false);
  const [templateDoc, setTemplateDoc] = useState("");
  const [collectionList, setCollectionList] = useState([]);

  const handleOpenAddPropertiesModal = () => {
    setPropertiesSwapper(properties);
    if (properties.length === 0) {
      handleNewProperty();
    }
    setAddProperties(true);
  };

  const closeAddPropertiesModal = () => {
    setPropertiesSwapper([]);
    setAddProperties(false);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#fff" : "#000",
      backgroundColor: state.isSelected ? "#005f7b" : "#fff",
      cursor: "pointer"
    }),
  };

  const createNftItemschema = Yup.object().shape({
    name: Yup.string().required("NFT name is required"),
    external_link: Yup.string().required("Link is required"),
    royalty_wallet_address: Yup.string().when("royalty_percentage", {
      is: (royalty_percentage) => Number(royalty_percentage) > 0,
      then: Yup.string().required("Royalty Wallet address is required"),
      otherwise: Yup.string(),
    }),
  });

  useEffect(() => {
    props.dispatch(
      postsFilesViewBase64Start({ post_unique_id: params.post_unique_id })
    );
    props.dispatch(
      fetchSinglePostStart({ post_unique_id: params.post_unique_id })
    );
    props.dispatch(fetchNftCollectionsListStart());
  }, [params.post_unique_id]);

  useEffect(() => {
    if (
      !skipRender &&
      !props.postsFilesViewBase64.loading &&
      !props.singlePost.loading &&
      Object.keys(props.postsFilesViewBase64.data).length > 0 &&
      Object.keys(props.singlePost.data).length > 0
    ) {
      let postData = {
        ...props.singlePost.data.post,
        postFiles: props.postsFilesViewBase64.data.post_files_data,
      };
      setTemplateDoc(nftTemlate.getNftTemplate(postData));
      setPost(postData);
    }
  }, [props.postsFilesViewBase64, props.singlePost]);

  useEffect(() => {
    if (
      !skipRender &&
      !props.postsFilesViewBase64.loading &&
      Object.keys(props.postsFilesViewBase64.data) === 0
    ) {
      history.push("/profile");
    }
  }, [props.postsFilesViewBase64]);

  useEffect(() => {
    if (
      !skipRender &&
      !props.singlePost.loading &&
      Object.keys(props.singlePost.data).length === 0
    ) {
      history.push("/profile");
    }
  }, [props.singlePost]);

  useEffect(() => {
    if (
      !skipRender &&
      !props.collectionList.loading &&
      Object.keys(props.collectionList.data).length === 0
    ) {
      history.push("/profile");
    } else if (!props.collectionList.loading &&
      Object.keys(props.collectionList.data).length > 0 && props.collectionList.data.nft_collections.length > 0) {
      let collections = props.collectionList.data.nft_collections
        .filter(
          (collection) =>
            collection.collection_type === 2
        )
        .map((collection, index) => {
          return { value: index, label: collection.name }
        })
      setCollectionList(collections)
    }
  }, [props.collectionList]);

  useEffect(() => {
    if (
      !skipRender &&
      !props.saveNftCollectionsItems.loading &&
      Object.keys(props.saveNftCollectionsItems.data).length > 0
    ) {
      props.dispatch(
        postNftStatusUpdateStart({
          post_unique_id: params.post_unique_id,
        })
      );
    }
  }, [props.saveNftCollectionsItems]);

  useEffect(() => {
    if (
      !skipRender &&
      !props.postNftStatusUpdate.loading &&
      Object.keys(props.postNftStatusUpdate.data).length > 0
    ) {
      history.push(`/post/${params.post_unique_id}`);
    }
    setSkipRender(false);
  }, [props.postNftStatusUpdate]);

  const handleNewProperty = () => {
    let newPropety = {
      name: "",
      value: "",
      nft_value_id: "",
    };
    setPropertiesSwapper([...propertiesSwapper, newPropety]);
  };

  const handlePropertyNameChange = (index, value) => {
    setPropertiesSwapper([
      ...propertiesSwapper.map((collection, collectionIndex) => {
        if (index === collectionIndex) {
          return {
            ...collection,
            name: value,
          };
        }
        return collection;
      }),
    ]);
  };

  const handlePropertyValueChange = (index, value) => {
    setPropertiesSwapper([
      ...propertiesSwapper.map((collection, collectionIndex) => {
        if (index === collectionIndex) {
          return {
            ...collection,
            value: value,
          };
        }
        return collection;
      }),
    ]);
  };

  const handlePropertyDelete = (removeCollection) => {
    setPropertiesSwapper(
      propertiesSwapper.filter((collection) => collection != removeCollection)
    );
  };

  const handlePropertySave = () => {
    const saveProperties = propertiesSwapper.filter(
      (collection) =>
        collection.name.trim() != "" && collection.value.trim() != ""
    );

    setProperties(saveProperties);
    closeAddPropertiesModal();
  };

  const handleSubmit = (values) => {
    setIpfsStatus(true);
    makeIpfs(values);
  };

  const makeIpfs = async (values) => {
    const baseData = post.postFiles;
    let ipfsData = [];
    baseData.map(async (file, i) => {
      let base64 = await convertDataURIToBinaryFF(file.post_file);
      const imageData = Buffer(base64);
      const result = await ipfs.add(imageData);
      ipfsData = [
        ...ipfsData,
        {
          post_file: "https://nftmeta.infura-ipfs.io/ipfs/" + result[0].hash,
          file_type: file.file_type,
        },
      ];
      if (ipfsData.length === baseData.length) {
        generateNFTHtml(values, ipfsData);
      }
    });
  };

  const generateNFTHtml = (values, ipfsData) => {
    const htmlNft = nftTemlate.getActualHTMLNft({
      total_likes: props.singlePost.data.post.total_likes,
      total_comments: props.singlePost.data.post.total_comments,
      content_formatted: props.singlePost.data.post.content_formatted,
      created: props.singlePost.data.post.created,
      post_unique_id: props.singlePost.data.post.post_unique_id,
      ipfsData: ipfsData,
    });
    values = { ...values, animation_url: htmlNft };
    let htmlData = Buffer(htmlNft);
    ipfs.add(htmlData, async (error, result) => {
      if (error) {
        console.error(error);
        return;
      }
      let nftHTMLIpfs = "https://nftmeta.infura-ipfs.io/ipfs/" + result[0].hash;
      // commonJsonGenerator(collectionItemData, itemFile, newData, nftContractData, nftHTMLIpfs);
      makeScreenShotIpfs(values, nftHTMLIpfs);
    });
  };

  const makeScreenShotIpfs = async (values, nftHTMLIpfs) => {
    let iframe = document.getElementsByClassName("nft-preview-img");
    const iwindow = iframe[0].contentWindow;
    const iDocument = iwindow.document;
    const data = await htmlToImage.toPng(
      iDocument.getElementsByTagName("html")[0]
    );
    values = { ...values, picture: data };
    let base64 = await convertDataURIToBinaryFF(data);
    const imageData = Buffer(base64);
    ipfs.add(imageData, async (error, result) => {
      if (error) {
        console.error(error);
        return;
      }
      let screenShotIpfs =
        "https://nftmeta.infura-ipfs.io/ipfs/" + result[0].hash;
      completed(values, nftHTMLIpfs, screenShotIpfs);
    });
  };

  const convertDataURIToBinaryFF = (dataURI) => {
    var BASE64_MARKER = ";base64,";
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    var raw = window.atob(dataURI.substring(base64Index));
    return Uint8Array.from(
      Array.prototype.map.call(raw, function (x) {
        return x.charCodeAt(0);
      })
    );
  };

  const completed = async (values, nftHTMLIpfs, screenShotIpfs) => {
    let allWords = "";
    let attributes = [];
    properties.map((property) => {
      attributes.push({ trait_type: property.name, value: property.value });
      allWords = allWords + " " + property.value;
    });
    let metadata = JSON.stringify({
      description: values.description,
      external_url: values.external_link,
      image: screenShotIpfs,
      animation_url: nftHTMLIpfs,
      name: values.name,
      text: allWords,
      attributes,
    });
    console.log(metadata);

    metadata = Buffer(metadata);
    const cidArr = await ipfs.add(metadata);
    const cid = cidArr[0].hash;
    // const tokenURIHash = await ipfsJson.cat(cid);
    props.dispatch(
      saveNftCollectionsItemsStart({
        ...values,
        token_uri: "https://nftmeta.infura-ipfs.io/ipfs/" + cid,
        file_type: 3,
        item_type: 2,
        is_minted: 0,
        properties: JSON.stringify(properties),
        nft_collection_id: selectedCollection.nft_collection_id,
        wallet_address: selectedCollection.deployed_wallet_address,
      })
    );
  };

  const validateFields = (value) => {
    let formattedValue = value.replaceAll(" ", "");
    let msg = "";
    if (formattedValue.length <= 0) msg = "Invalid formate.";
    return msg;
  };

  return (
    <>
      <div className="create-new-item-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="create-new-item-header-sec">
                <h3>Create New Item</h3>
              </div>
            </Col>
          </Row>
          {props.singlePost.loading ? (
            <Row>
              <Col md={6}>
                <div className="nft-preview-img-sec">
                  <Skeleton className="nft-preview-img" />
                </div>
              </Col>
              <Col md={6}>
                <div className="nft-preview-img-sec">
                  <Skeleton className="nft-preview-img" />
                </div>
              </Col>
            </Row>
          ) : (
            <Formik
              initialValues={{
                name: "",
                description: props.singlePost.data.post.content_formatted,
                external_link: "",
                royalty_percentage: 0,
                royalty_wallet_address: "",
              }}
              className="w-100"
              validationSchema={createNftItemschema}
              onSubmit={(values) => handleSubmit(values)}
              enableReinitialize
            >
              {({ errors, touched, setFieldValue, values, isSubmitting }) => (
                <FORM className="create-new-item-form edit-profile-form">
                  <Row>
                    <Col md={6}>
                      <div className="nft-preview-img-sec">
                        {templateDoc ? (
                          <iframe
                            srcDoc={templateDoc}
                            className="nft-preview-img"
                          />
                        ) : (
                          <Skeleton className="nft-preview-img" />
                        )}
                      </div>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Name*</Form.Label>
                        <Field
                          className={`form-control ${touched.name && errors.name ? "is-invalid" : ""
                            }`}
                          placeholder="NFT name"
                          name="name"
                          validate={validateFields}
                        />
                        <ErrorMessage
                          component="div"
                          name="name"
                          className="invalid-feedback mt-3"
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Field
                          name="description"
                          render={({
                            field /* { name, value, onChange, onBlur } */,
                          }) => (
                            <Form.Control
                              type="text"
                              {...field}
                              placeholder="Description"
                              as="textarea"
                              rows={3}
                            />
                          )}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>External Link*</Form.Label>
                        <p>
                          Marketplace will include a link to this URL on this
                          item's detail page, so that users can click to learn
                          more about it. You are welcome to link to your own
                          webpage with more details.
                        </p>
                        <Field
                          name="external_link"
                          className={`form-control 
                        ${touched.external_link && errors.external_link
                              ? "is-invalid"
                              : ""
                            }`}
                          placeholder="https://yoursite.io/item/123"
                          validate={validateFields}
                        />
                        <ErrorMessage
                          component="div"
                          name="external_link"
                          className="invalid-feedback mt-3"
                        />
                      </Form.Group>
                      {/* <Form.Group>
                        <Form.Label>Collection*</Form.Label>
                        <p>
                          If you want to add new collection, you can create
                          collection in{" "}
                          <a
                            href={configuration.get(
                              "configData.metaocean_frontend_url"
                            )}
                            target="_blank"
                          >
                            NFT Social Marketplace
                          </a>
                        </p>
                        {props.collectionList.loading ? null : props
                            .collectionList.data ? (
                          props.collectionList.data.nft_collections.length >
                          0 ? (
                            <Form.Control
                              as="select"
                              className="mr-sm-2"
                              id="inlineFormCustomSelect"
                              custom
                              name="collections"
                              onChange={(e) =>
                                setSelectedCollection(
                                  props.collectionList.data.nft_collections[
                                    e.target.value
                                  ]
                                )
                              }
                            >
                              <option disabled selected>
                                Choose a Collection
                              </option>
                              {props.collectionList.data.nft_collections
                                .filter(
                                  (collection) =>
                                    collection.collection_type === 2
                                )
                                .map((collection, i) => (
                                  <option
                                    value={i}
                                    key={i}
                                    selected={collection === selectedCollection}
                                  >
                                    {collection.name}
                                  </option>
                                ))}
                            </Form.Control>
                          ) : null
                        ) : (
                          <div className="text-danger">
                            Error fetching collection details, please try again.
                          </div>
                        )}
                      </Form.Group> */}
                      <Form.Group>
                        <Form.Label>Collection*</Form.Label>
                        <p>
                          If you want to add new collection, you can create
                          collection in{" "}
                          <a
                            href={configuration.get(
                              "configData.metaocean_frontend_url"
                            )}
                            target="_blank"
                          >
                            NFT Social Marketplace
                          </a>
                        </p>
                        {props.collectionList.loading ? null : props
                          .collectionList.data ? (
                          props.collectionList.data.nft_collections.length >
                            0 ? (
                            <div className="new-select-dropdown mb-5">
                              <Select options={collectionList} styles={customStyles}
                                onChange={(collection) => setSelectedCollection(
                                  props.collectionList.data.nft_collections[
                                  collection.value
                                  ]
                                )}
                              />
                            </div>
                          ) : null
                        ) : (
                          <div className="text-danger">
                            Error fetching collection details, please try again.
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  {selectedCollection &&
                    selectedCollection.royalty_type == 2 ? (
                    <div className="royality-create-new-item">
                      <Row>
                        <Col md={6}>
                          <Form.Group>
                            <Form.Label>Royalties</Form.Label>
                            <p>
                              Collect a fee when a user re-sells an Item you
                              originally created. This is deducted from the
                              final sale price and paid monthly to a payout
                              address of your choosing.
                            </p>
                            <Field
                              className="form-control"
                              name="royalty_percentage"
                              type="number"
                              placeholder="1"
                            />
                          </Form.Group>
                        </Col>
                        {Number(values.royalty_percentage) > 0 && (
                          <Col md={6}>
                            <Form.Group>
                              <Form.Label>Royalty Wallet Address*</Form.Label>
                              <p>
                                Royalty percentage will be sent to the provided
                                wallet address. Ensure the wallet address is
                                correct, you will not be able to change the
                                wallet address once set.
                              </p>
                              <Field
                                className="form-control"
                                name="royalty_wallet_address"
                                type="text"
                                placeholder="Wallet address"
                              />
                              <ErrorMessage
                                component="div"
                                name="royalty_wallet_address"
                                className="invalid-feedback mt-3"
                              />
                            </Form.Group>
                          </Col>
                        )}
                      </Row>
                    </div>
                  ) : null}
                  <Row>
                    <Col md={12}>
                      <Form.Group>
                        <div className="properties-collapse-sec">
                          <div className="properties-collapse-info">
                            <h4>Properties</h4>
                            <p>textual traits that show up as rectangles</p>
                          </div>
                          <div className="properties-collapse-btn-sec">
                            <Button
                              onClick={() => handleOpenAddPropertiesModal()}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm144 276c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92h-92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z" />
                              </svg>
                            </Button>
                          </div>
                        </div>
                      </Form.Group>
                      <div className="properties-item-box">
                        {properties.map((property, i) => (
                          <>
                            {property.name != "" && property.value != "" && (
                              <div className="properties-item-card">
                                <p>{property.name}</p>
                                <h4>{property.value}</h4>
                              </div>
                            )}
                          </>
                        ))}
                      </div>
                    </Col>
                  </Row>
                  <div className="mt-4 save-btn-sec">
                    <Button
                      type="submit"
                      className="btn btn-success"
                      disabled={
                        !termsAgreed ||
                        !selectedCollection ||
                        ipfsStatus ||
                        !templateDoc
                      }
                    >
                      {ipfsStatus ? "Loading" : "Save"}
                    </Button>
                  </div>
                </FORM>
              )}
            </Formik>
          )}
        </Container>
      </div>
      <AddPropertiesModal
        addProperties={addProperties}
        closeAddPropertiesModal={closeAddPropertiesModal}
        properties={propertiesSwapper}
        handleNewProperty={handleNewProperty}
        handlePropertyNameChange={handlePropertyNameChange}
        handlePropertyValueChange={handlePropertyValueChange}
        handlePropertyDelete={handlePropertyDelete}
        handlePropertySave={handlePropertySave}
      />
    </>
  );
};

const mapStateToPros = (state) => ({
  singlePost: state.post.singlePost,
  postsFilesViewBase64: state.post.postsFilesViewBase64,
  collectionList: state.nft.nftCollectionsList,
  saveNftCollectionsItems: state.nft.saveNftCollectionsItems,
  postNftStatusUpdate: state.post.postNftStatusUpdate,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(CreateNewItemIndex);
