import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Image,
  Container,
  Tab,
  Nav,
  Form,
  FormGroup,
  InputGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import { Link, useLocation, useHistory } from "react-router-dom";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import LoginSlider from "./LoginSlider";
import "react-phone-number-input/style.css";
import { connect } from "react-redux";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  userRegisterStart,
} from "../../store/actions/UserAction";
import PhoneNumberVerificationModal from "./PhoneNumberVerificationModal";

const OnBoardingRegisterIndex = (props) => {
  const location = useLocation();
  const history = useHistory();

  const formRef = useRef(null);
  const [skipRender, setSkipRender] = useState(true);
  const [numberVerification, setNumberVerification] = useState(false);
  const [email, setEmail] = useState(
    localStorage.getItem("join_email") ? localStorage.getItem("join_email") : ""
  );
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirm: false,
  });

  const closeNumberVerificationModal = () => {
    setNumberVerification(null);
  };

  useEffect(() => {
    localStorage.removeItem("join_email");
  }, []);

  useEffect(() => {
    if (
      !skipRender &&
      !props.registerInputData.loading &&
      Object.keys(props.registerInputData.data).length > 0
    ) {
      formRef.current.resetForm();
      if (props.registerInputData.data.data.user.onboarding_step === 1) {
        setNumberVerification(props.registerInputData.data.data.user.mobile);
      } else {
        history.push("/view-private-key");
      }
    }
    setSkipRender(false);
  }, [props.registerInputData]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    mobile: Yup.string().required("Required"),
    password: Yup.string().required("Required"),
    confirm_password: Yup.string()
      .required("Required")
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Both password need to be the same"
        ),
      }),
  });

  const validatePassword = (value) => {
    let error;
    if (!value) {
      error = "Required";
    } else if (value.length < 6) {
      error = "At least 6 letters";
    } else {
      let trimmed = value.replaceAll(" ", "");
      if (trimmed !== value) {
        error = "No space allowed";
      }
    }
    return error;
  };

  const validateMobileNumber = (value) => {
    if (value) {
      if (!isPossiblePhoneNumber(value)) {
        formRef.current.setFieldError("mobile", "Not a Phone number");
        return false;
      } else if (!isValidPhoneNumber(value)) {
        formRef.current.setFieldError("mobile", "Invalid Phone number");
        return false;
      } else {
        formRef.current.setFieldError("mobile", "");
        return true;
      }
    } else {
      formRef.current.setFieldError("mobile", "Required");
      return false;
    }
  };

  const register = (values) => {
    if (validateMobileNumber(values.mobile)) {
      const intlNo = formatPhoneNumberIntl(values.mobile);
      const countryCode = intlNo.substring(
        intlNo.indexOf("+") + 1,
        intlNo.indexOf(" ")
      );
      const mobile = intlNo
        .substring(intlNo.indexOf(" "), intlNo.length)
        .replaceAll(" ", "");
      props.dispatch(
        userRegisterStart({
          ...values,
          mobile: mobile,
          country_code: countryCode,
        })
      );
    }
  };

  return (
    <>
      <div className="authentication-sec">
        <Container>
          <div className="autentication-box">
            <Row className="align-items-center">
              <Col md={12} xl={6} lg={6} className="border-right resp-order">
                <LoginSlider />
              </Col>
              <Col md={12} xl={6} lg={6} className="border-left">
                <div className="login-auth-sec">
                  <div className="Login-nav-link">
                    <ul>
                      <li
                        className={
                          location.pathname === "/" ? "active" : ""
                        }
                      >
                        <Link to="/">Sign In</Link>
                      </li>
                      <li
                        className={
                          location.pathname === "/register" ? "active" : ""
                        }
                      >
                        <Link to="/register">Sign Up</Link>
                      </li>
                    </ul>
                  </div>
                  <Formik
                    initialValues={{
                      name: "",
                      email: email,
                      mobile: "",
                      password: "",
                      confirm_password: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => register(values)}
                    innerRef={formRef}
                  >
                    {({ errors, values, touched, setFieldValue }) => (
                      <FORM className="auth-form">
                        <div className="sign-in-sec">
                          <FormGroup className="mb-3">
                            <Form.Label>Name*</Form.Label>
                            <InputGroup className={`${touched.name && errors.name ? "is-invalid" : ""
                              }`}>
                              <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1">
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/assets/images/auth/user.svg"
                                    }
                                  />
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <Field
                                className={`form-control ${touched.name && errors.name
                                  ? "is-invalid"
                                  : ""
                                  }`}
                                type="text"
                                placeholder="Enter Your Name"
                                name="name"
                              />
                            </InputGroup>
                            <ErrorMessage
                              component={"div"}
                              name="name"
                              className="text-danger small"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Form.Label>E-mail*</Form.Label>
                            <InputGroup
                              className={`mb-0 ${touched.email && errors.email
                                ? "is-invalid"
                                : ""
                                }`}>
                              <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1">
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/assets/images/auth/mail.svg"
                                    }
                                  />
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <Field
                                className={`form-control ${touched.email && errors.email
                                  ? "is-invalid"
                                  : ""
                                  }`}
                                type="email"
                                placeholder="Enter Your Email Address"
                                name="email"
                              />
                            </InputGroup>
                            <ErrorMessage
                              component={"div"}
                              name="email"
                              className="text-danger small"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Form.Label>Phone Number*</Form.Label>

                            <PhoneInput
                              defaultCountry="US"
                              placeholder="Enter phone number"
                              value={values.mobile}
                              onChange={(value) =>
                                setFieldValue("mobile", value)
                              }
                              onBlur={() => validateMobileNumber(values.mobile)}
                              international
                              countryCallingCodeEditable={false}
                              className={`${touched.mobile && errors.mobile
                                ? "is-invalid"
                                : ""
                                }`}
                            />
                            <ErrorMessage
                              component={"div"}
                              name="mobile"
                              className="text-danger small"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Form.Label>Password*</Form.Label>
                            <InputGroup className={`mb-3 ${touched.password && errors.password ? "is-invalid" : ""}`}>
                              <InputGroup.Prepend>
                                <InputGroup.Text>
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/assets/images/auth/lock.svg"
                                    }
                                  />
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <Field
                                className={`form-control ${touched.password && errors.password ? "is-invalid" : ""}`}
                                type={showPassword.password ? "text" : "password"}
                                placeholder="Enter Your Password"
                                name="password"
                                validate={validatePassword}
                              />
                              <InputGroup.Append>
                                <InputGroup.Text
                                  onClick={() => setShowPassword({
                                    ...showPassword,
                                    password: !showPassword.password
                                  })}
                                >
                                  <Image
                                    src={
                                      showPassword.password
                                        ? window.location.origin +
                                        "/assets/images/auth/eye_dash.svg"
                                        : window.location.origin +
                                        "/assets/images/auth/eye.svg"
                                    }
                                  />
                                </InputGroup.Text>
                              </InputGroup.Append>
                            </InputGroup>
                            <ErrorMessage
                              component={"div"}
                              name="password"
                              className="text-danger small"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Form.Label>Confirm Password*</Form.Label>
                            <InputGroup className={`mb-3 ${touched.confirm_password && errors.confirm_password ? "is-invalid" : ""}`}>
                              <InputGroup.Prepend>
                                <InputGroup.Text>
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/assets/images/auth/lock.svg"
                                    }
                                  />
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <Field
                                className={`form-control 
                                ${touched.confirm_password && errors.confirm_password ? "is-invalid" : ""}
                                `}
                                type={showPassword.confirm ? "text" : "password"}
                                placeholder="Re-enter Your Password"
                                name="confirm_password"
                              />
                              <InputGroup.Append>
                                <InputGroup.Text
                                  onClick={() => setShowPassword({
                                    ...showPassword,
                                    confirm: !showPassword.confirm,
                                  })}
                                >
                                  <Image
                                    src={
                                      showPassword.confirm
                                        ? window.location.origin +
                                        "/assets/images/auth/eye_dash.svg"
                                        : window.location.origin +
                                        "/assets/images/auth/eye.svg"
                                    }
                                  />
                                </InputGroup.Text>
                              </InputGroup.Append>
                            </InputGroup>
                            <ErrorMessage
                              component={"div"}
                              name="confirm_password"
                              className="text-danger small"
                            />
                          </FormGroup>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicCheckbox"
                          >
                            <div className="agree-terms-sec">
                              <Form.Check
                                type="checkbox"
                                checked={termsAgreed}
                                onChange={() => setTermsAgreed(!termsAgreed)}
                              />
                              <p>
                                I agree to <Link to="#">Terms of Use</Link>
                              </p>
                            </div>
                          </Form.Group>
                          <div className="onboard-btn-sec">
                            <Button
                              className="onboard-btn"
                              type="submit"
                              disabled={props.registerInputData.buttonDisable || !termsAgreed}
                            >
                              {props.registerInputData.loadingButtonContent
                                ? props.registerInputData.loadingButtonContent
                                : "Register"}
                            </Button>
                          </div>
                          <div className="sign-up-link">
                            <h4>
                              Already have an account?
                              <Link to="/">Sign In</Link>{" "}
                            </h4>
                          </div>
                        </div>
                      </FORM>
                    )}
                  </Formik>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      {numberVerification ? (
        <PhoneNumberVerificationModal
          numberVerification={true}
          mobile={numberVerification}
          closeNumberVerificationModal={closeNumberVerificationModal}
        />
      ) : null}
    </>
  );
};

const mapStateToPros = (state) => ({
  registerInputData: state.users.registerInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(OnBoardingRegisterIndex);
