import React from "react";
import Slider from "react-slick";
import { Image } from "react-bootstrap";

const LoginSlider = () => {
  var slider = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrow: false,
  };
  return (
    <div>
      <div className="auth-slider">
        <Slider {...slider}>
          <div className="login-slider">
            <Image src={window.location.origin + "/assets/images/auth/img-1.png"} />
            <div className="auth-slider-content">
              <h3>Lorem Ipsum Dolor</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
          </div>
          <div className="login-slider">
            <Image src={window.location.origin + "/assets/images/auth/img-2.png"} />
            <div className="auth-slider-content">
              <h3>Lorem Ipsum Dolor</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
          </div>
          <div className="login-slider">
            <Image src={window.location.origin + "/assets/images/auth/img-3.png"} />
            <div className="auth-slider-content">
              <h3>Lorem Ipsum Dolor</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default LoginSlider;
