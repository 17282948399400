import React, { useState, useEffect } from 'react';
import {
	InputGroup,
	FormControl,
	Image,
	Modal,
	Media,
	Row,
	Col,
	Form,
	Button,
} from "react-bootstrap";
import { connect } from 'react-redux';
import { translate, t } from 'react-multi-lang';
import configuration from "react-global-configuration";
import { fetchCardDetailsStart } from '../../../store/actions/CardsAction';
import { fetchWalletDetailsStart } from "../../../store/actions/WalletAction";

const PaymentMethodCard = (props) => {

	const [isOnlyWalletPayment, setIsOnlyWalletPayment] = useState(configuration.get("configData.is_only_wallet_payment"));
	const [showCards, setShowCards] = useState(false);

	useEffect(() => {
		props.dispatch(fetchCardDetailsStart());
		props.dispatch(fetchWalletDetailsStart());
	}, []);

	const changePaymentType = (type, card = null) => {
		props.setPaymentType(type);
		if (type === "CARD") {
			props.setSelectedCard(card);
		} else {
			props.setSelectedCard(null);
		}
	}

	return (
		<Col md={12} xl={6}>
			<div className="payment-method-sec">
				<h4 className="payment-modal-title">Payment Method</h4>
				<div className="wallet-grid-card">
					<label class="card" onClick={() => changePaymentType("WALLET")}>
						<div className="wallet-payment-title">Wallet</div>
						{!props.wallet.loading ?
							<div className="wallet-balance">
								<img src="assets/images/money-bag.svg" alt="" width="20" />
								<p>{props.wallet.data.user_wallet.remaining_formatted}</p>
							</div>
							: null
						}
						<input
							name="plan"
							className="radio"
							type="radio"
							checked={props.paymentType === "WALLET"}
						/>
					</label>
					{isOnlyWalletPayment == 0 ?
						<>
							{configuration.get("configData.is_ccbill_enabled") == 1 &&
								configuration.get("configData.flex_form_id") !== "" &&
								configuration.get("configData.salt_key") !== "" ?
								<label className="card" onClick={() => changePaymentType("CCBILL")}>
									<div className="wallet-payment-title">{t("ccbill")}</div>
									<input
										name="plan"
										className="radio"
										type="radio"
										checked={props.paymentType === "CCBILL"}
									/>
								</label>
								: null
							}
							{configuration.get("configData.is_paypal_enabled") == 1 &&
								configuration.get("configData.PAYPAL_ID") !== "" ?
								<label className="card" onClick={() => changePaymentType("PAYPAL")}>
									<div className="paypal-logo-img">
										<img src={window.location.origin + "/assets/images/Paypal-logo.png"} alt="" />
									</div>
									{/* <div className="paypal-account-details">
							<h5>Codegama</h5>
							<p>codegama@gmail.com</p>
						</div> */}
									<input
										name="plan"
										className="radio"
										type="radio"
										checked={props.paymentType === "PAYPAL"}
									/>
								</label>
								: null
							}
						</> : null
					}
				</div>
				{isOnlyWalletPayment == 0 ?
					configuration.get("configData.is_stripe_enabled") == 1 &&
						configuration.get("configData.stripe_publishable_key") !== "" &&
						configuration.get("configData.stripe_secret_key") !== "" ?
						<div className="payment-multiple-card">
							<h5 className="wallet-payment-title mb-3">Cards</h5>
							{props.cards.loading ?
								"Loading"
								: <div className="payment-multiple-box">
									{props.cards.data.cards.length > 0 ? (
										props.cards.data.cards.map((card, i) =>
											<label className="card" onClick={() => changePaymentType("CARD", card.id)} key={i}>
												<div className="payment-card-details">
													<img src={card.picture} alt="" />
													<h5>{card.card_holder_name}</h5>
													<p>xxxx-xxxx-xxxx-{card.last_four}</p>
													{card.is_default === 1 && <p className="text-success">{t("default_card")}</p>}
												</div>
												<input
													name="plan"
													className="radio"
													type="radio"
													checked={props.paymentType === "CARD" && props.selectedCard === card.id}
												/>
											</label>
										)) : "No Card"
									}
								</div>
							}
							<div className="add-card mt-4">
								<Button onClick={() => props.setShowAddCard(true)}>
									ADD CARDS{" "}
									<span>
										<img src="assets/images/plus.png" alt="" />
									</span>
								</Button>
							</div>
						</div>
						: null
					: null
				}
			</div>
		</Col>
	);
}

const mapStateToPros = (state) => ({
	wallet: state.wallet.walletData,
	cards: state.cards.cardDetails,
});

function mapDispatchToProps(dispatch) {
	return { dispatch };
}

export default connect(
	mapStateToPros,
	mapDispatchToProps
)(translate(PaymentMethodCard));