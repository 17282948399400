import React, { useState, useEffect } from "react";
import {
  Container,
  Image,
  Row,
  Col,
  Button,
  Table,
  Form,
  InputGroup,
  Modal,
} from "react-bootstrap";
import OtpInput from "react-otp-input";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { regenerateMobileVerificationCodeStart, verifyMobileStart } from "../../store/actions/WalletAppAction";
import Countdown from "react-countdown";
import { forgotPasswordStart, verifyForgotPasswordStart } from "../../store/actions/UserAction";

const PhoneNumberVerificationModal = (props) => {
  const history = useHistory();


  const [mobile, setMobile] = useState(props.mobile);
  const [skipRender, setSkipRender] = useState(true);
  const [otp, setOTP] = useState("");
  const [time, setTime] = useState(Date.now());
  const [isTimer, setIsTimer] = useState(true);

  const handleChange = (otp) => setOTP(otp);

  const verifyMobileCode = e => {
    e.preventDefault();
    props.dispatch(verifyForgotPasswordStart({
      [props.forgotPasswordData.attribute]: props.forgotPasswordData.value,
      verification_code: otp,
    }));
  }

  useEffect(() => {
    if (!skipRender && !props.verifyForgotPassword.loading && Object.keys(props.verifyForgotPassword.data).length > 0) {
      history.push(`/reset-password/${props.verifyForgotPassword.data.data.token}`);
    }
    setSkipRender(false);
  }, [props.verifyForgotPassword]);

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      setIsTimer(false);
      return <></>;
    } else {
      return (
        <div>
          Resend code in <span style={{ color: "#008fff" }}>{seconds}</span> s
        </div>
      );
    }
  };

  const handleForgotPassword = () => {
    props.dispatch(forgotPasswordStart({
      [props.forgotPasswordData.attribute]: props.forgotPasswordData.value,
    }));
  };

  return (
    <>
      <Modal
        className="modal-dialog-center phone-number-verification"
        size="md"
        centered
        show={props.passwordVerification}
        onHide={props.closePasswordVerificationModal}
      >
        {/* <Modal.Header closeButton>
            <Modal.Title>Add Properties</Modal.Title>
          </Modal.Header> */}
        <Modal.Body>
          <Button
            className="modal-close"
            onClick={() => props.closePasswordVerificationModal()}
          >
            <Image
              className="close-icon"
              src={
                window.location.origin +
                "/assets/images/onboarding-icons/modal-close.svg"
              }
            />
          </Button>
          {props.forgotPasswordData == "mobile" ? (
            <>
              <h4>Verfication Using Phone Number</h4>
              <p>
                Enter the verification code sent to your phone to create new password
              </p>
            </>
          ) : (
            <>
              <h4>Verfication Using Email</h4>
              <p>
                Enter the verification code sent to your phone to create new password
              </p>
            </>
          )}
          <div className="phone-number-verification-sec">
            <Form onSubmit={verifyMobileCode}>
              <div className="verification-item">
                <OtpInput
                  value={otp}
                  onChange={handleChange}
                  numInputs={6}
                  isInputNum={true}
                  shouldAutoFocus={true}
                  separator={<span>-</span>}
                />
              </div>
              {props.verifyForgotPassword.error ?
                <div className="text-danger text-center mb-3">
                  {props.verifyForgotPassword.error}
                </div>
                : null
              }
              <div className="verification-btn-sec onboard-btn-sec">
                {isTimer ? (
                  <Countdown date={time + 60000} renderer={renderer} />
                ) : (
                  <Link
                    to="#"
                    className="resend-link"
                    onClick={e => {
                      if (props.forgotPassword.buttonDisable)
                        e.preventDefault()
                      else {
                        handleForgotPassword()
                        setTime(Date.now());
                        setIsTimer(true);
                      }
                    }
                    }
                  >
                    {props.forgotPassword.loadingButtonContent ?
                      props.forgotPassword.loadingButtonContent
                      : "Resend"
                    }
                  </Link>
                )}
                <Button
                  type="submit"
                  className="verify-btn"
                  disabled={!otp || otp.length < 6 || props.verifyForgotPassword.buttonDisable}
                >
                  {props.verifyForgotPassword.loadingButtonContent ?
                    props.verifyForgotPassword.loadingButtonContent
                    : "Verify"
                  }
                </Button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  forgotPassword: state.users.forgotPasswordInputData,
  verifyForgotPassword: state.users.verifyForgotPassword,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(PhoneNumberVerificationModal);
